import axios from "axios";
import DEV from "../url";

export class LabellisationService{

    getAll()
    {
        return axios.get(`${DEV}labellisation/all`);
    }
}
