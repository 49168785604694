
import axios from "axios";
import DEV from "../url";

export class ContactService {


    contactus(data)
    {
        return axios.post(`${DEV}contact/sendMail`,data).then((res)=>res.data);
    }


}
