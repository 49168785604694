import axios from "axios";
import DEV from "../url";

export class PackService{

    async all(){
        const res = await axios.get(`${DEV}packs/all`);
        return res.data;
    }

    getbyid(id){
        return axios.get(`${DEV}packs/get/${id}`).then((res)=>res.data);
    }
}
