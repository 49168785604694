import axios from 'axios';
import DEV from "../url";

export class RestoService{
    
    async all(){
        const res = await axios.get(`${DEV}restaurant/all`);
        return res.data;
    }

    async allTisanerie(){
        const res = await axios.get(`${DEV}restaurant/alltisanerie`);
        return res.data;
    }

    get(id) {
        return axios.get(`${DEV}restaurant/get/${id}`).then((res)=>res.data);
        
    }

    getMenuImages() {
          return axios.get(`${DEV}restaurant/menuCards`).then((res)=>res.data);

    }

}
