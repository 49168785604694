import axios from 'axios';
import DEV from "../url";

export class FeedbackService {


    create(data){
        return axios.post(`${DEV}feedback/create`,data).then((res)=>res.data);
    }

    async all()
    {
        const res = await axios.get(`${DEV}feedback/all`);
        return res.data;
    }
}
