import React from 'react';

function LaisserCommentaireHeading(props) {
    return (
        <div className="page-heading header-text">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Laisser un Commentaire</h1>
                        <span>N'hésitez pas à nous laisser votre avis !</span>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default LaisserCommentaireHeading;