import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { BannerService } from "../../../services/BannerService";
import { LiensUtilesService } from "../../../services/LiensUtilesService";
import DEV from "../../../url";
import { ContactInformation } from "../../../services/ContactInformation";

function Items() {

 // const lien = new LiensUtilesService();
//  const contact = new ContactInformation();

 // const [data1, setData1] = useState([]);

 // const [data, setData] = useState([]);

 /* const fetchData = () => {
    lien.all().then((res) => {
      setData(res);
    });
  };*/

 /* const fetchData1 = () => {
    contact.emailAndRenseignement().then((res) => {
      setData1(res);
    });
  };*/

  useEffect(() => {
//    fetchData();
    //fetchData1();
  });
  const service = new BannerService();
  const [items, setItems] = useState([null]);
  useEffect(() => {
    service.getBanners().then((res) => {
      setItems(res);
    });
  }, []);
  return (
    <React.Fragment>
      <Carousel
        style={{ height: 'auto' }}
        interval={0}
        slide={true}
        wrap={false}
        nextLabel={false}
        prevLabel={false}
      >
        {items.map((item, index) => (
          <Carousel.Item key={index}>
            <img
              src={`${DEV}uploads/banners/${item?.img}`}
              className="d-block w-100"
              alt={item?.title}
            />
            <div className="text-content space-mobile">
              <h6
                className="mx-3 mt-5"
                style={{
                  color: "#03AC13",
                  fontWeight: "bold",
                  fontSize: "22px",
                }}
              >
                {item?.subtitle}
              </h6>
              <h1 className="mx-3 text-white title-banner-size-mobile">{item?.title}</h1>
              <div className="row">
                <div className="col-11 mx-3 ">
                  <p
                    className="show-text"
                    style={{ color: "#fff", fontSize: "18px" }}
                  >
                    {item?.text}
                  </p>
                </div>
              </div>
              <div align="center">

             
              <div className="row mt-3 last" align="center">
              <div className="col-lg-4 col-sm-12">

              
                    <div  className="">
                      
                          <a 
                          className="filled-button  text-center text-banner-btn-size"
                          href={`tel:+21623724000`}>
                            <i className={`pi pi-phone`}></i>
                             +216 23 724 000
                          </a>
                        
                    </div>
                
                </div>

                <div className="col-lg-4 col-sm-12">
                {/* {data.map((row, indexRow) => ( */}
                  <div className="">
                   {/*  {row.title
                      .toUpperCase()
                      .includes("VISITE" || "VIRTUELLE") && ( */}
                        <a
                          target={"_blank"}
                          style={{ textTransform: "none" }}
                          href={'https://www.maisonsdhotesentunisie.com/vr/maisons/zaghouan-dar_zaghouan/'}
                          className="filled-btn-visite  text-center text-banner-btn-size"
                          rel="noreferrer"
                        >
                          Faites une visite virtuelle
                        </a>
                     {/*  )} */}
                  </div>
               {/*  ))} */}
                </div>
                <div className="col-lg-4 col-sm-12">
                  <a
                    style={{ textTransform: "none" }}
                    href={'https://darzaghouan.kinko.info/iframe?key=H-sVS8XcnRsSKSYi5T5rbNRA4n'}
                    className="filled-button  text-center text-banner-btn-size"
                  >
                    Réservez dès maintenant
                  </a>
                </div>
              </div>
              </div>

              <div className="mt-4"></div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </React.Fragment>
  );
}

export default Items;
