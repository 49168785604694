import React from 'react';
import SpaHeading from "./SpaHeading";
import SpaItems from "./SpaItems";


const SpaIndex = () => {

    return(
        <React.Fragment>
            <SpaHeading/>
             <div className="single-services" id={"spa"}>
            <SpaItems/>
            
             </div>
        </React.Fragment>
    )
}

export default SpaIndex;
