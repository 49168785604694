import React from "react";

function AdditionInformation() {
    return (
        <React.Fragment>
        <div className="contact-information">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="contact-item">
                            <i className="fa fa-phone"></i>
                            <h4>TéléPhone</h4>
                            <p>Pour plus de renseignements.</p>
                            <a href="#">+216 31 32 14 14</a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="contact-item">
                            <i className="fa fa-envelope"></i>
                            <h4>Email</h4>
                            <p>Pour plus de détails.</p>
                            <a href="#">darzaghouan@gmail.com</a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="contact-item">
                            <i className="fa fa-map-marker"></i>
                            <h4>Localisation</h4>
                            <p>1100 a cote de l'hopital de Zaghouan <br/> Zaghouan, Tunisia</p>
                            <a href="#map">Voir dans Google Maps</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
    );
}

export default AdditionInformation;
