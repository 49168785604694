import Axios from 'axios'
import DEV from "../url";

export class HeadingService {

    allHeb() {
        const res = Axios.get(`${DEV}heading/hebergement`);
        return res;
    }

    allRe() {
        const res =  Axios.get(`${DEV}heading/restauration`);
        return res;
    }

    allTer() {
        const res = Axios.get(`${DEV}heading/terroir`);
        return res;
    }

    allAct() {
        const res = Axios.get(`${DEV}heading/activite`);
        return res;
    }

    allEvt() {
        const res = Axios.get(`${DEV}heading/evenementiel`);
        return res;
    }

    allPck() {
        const res =  Axios.get(`${DEV}heading/pack`);
        return res;
    }

    allOnParNous() {
        const res = Axios.get(`${DEV}heading/onparledenous`);
        return res;
    }


    allOnraconte() {
        const res = Axios.get(`${DEV}heading/onracontepourvous`);
        return res;
    }



    allNotreHist() {
        return Axios.get(`${DEV}heading/notrehistoire`);
    }

    allRégles() {
        const res = Axios.get(`${DEV}heading/regles`);
        return res;
    }

    allHammam() {
        const res = Axios.get(`${DEV}heading/hammam`);
        return res;
    }

    allRéserver() {
        const res =  Axios.get(`${DEV}heading/reserver`);
        return res;
    }

}
