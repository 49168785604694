import React from "react";
import ActivitiesTabs from "./ActivitiesTabs";

function Activities() {
    return (
        <React.Fragment>
            <div className="more-info">
                <div className="container">
                    <div className="section-heading">
                        <h2> <em>Nos activités</em>&nbsp; suggérées</h2>
                        <span style={{textTransform:'none'}}>
                        Nous vous proposons une large gamme d'activités intérieures et extérieures pour petits et grands

                        </span>
                    </div>
                    <ActivitiesTabs />
                </div>
            </div>


        </React.Fragment>
    );
}
export default Activities;
