import React, {useState,useEffect} from 'react';

import {PackService} from '../../../../services/PackService';
import {useParams} from "react-router-dom";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from 'dompurify';
import PackHeading from "./PackHeading";
import DEV from '../../../../url';
function PackDetails() {

    const _ = new PackService();
    const {id} = useParams();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState("");
    const [type, setType] = useState("");
    const [details, setDetails] = useState();
    const [rules, setRules] = useState([]);
    const [price, setPrice] = useState("");
    const [maxCapacity, setMaxCapacity] = useState(0);


    const get = async () => {
        await _.getbyid(id).then((res) => {
            setTitle(res.title);
            setDescription(res.description);
            setImg(res.img);
            setType(res.type);
            setDetails(res.details);
            setRules(res.rules);
            setPrice(res.price);
            setMaxCapacity(res.maxCapacity);
        });
    }

    useEffect(() => {
        get();
    }, []);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [convertedContent, setConvertedContent] = useState(null);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
    }


    return (
        <React.Fragment>
            <PackHeading/>
            <div className="row mt-5" id={"packs"}>
                <div className="col-md-6 col-lg-6 col-sm-12"  align={"center"}>
                    {img ? <img alt={title} className="" src={`${DEV}uploads/packs/${img}`} width={300} height={400} /> : <></>}
                </div>

                <div className="col-md-6">
                    <div className="row">
                        <div className="col-lg-4 col-sm-12">
                            {title ? <h3 className='text-mobile-center'>{title}</h3>:<></>}
                        </div>
                        <div className={"col-lg-8 col-sm-12 mt-2 reserver-mobile-center"}>
                            <b>A partir de </b>
                            {price && <b className={"text-success"}>{price} {"DT / Personne"}</b>}
                        </div>
                    </div>


                    <div className="row mt-4">
                        <div className="col-lg-12 col-sm-12 reserver-mobile-center">
                            <b className=''> Détails du Pack </b>
                        </div>
                       <div className='container'>
                       <div className="col-lg-7 col-sm-12 col-sm-12  mt-3">
                            {description &&
                                <span className='mt-5' dangerouslySetInnerHTML={createMarkup(description)} />
                            }
                        </div>
                       </div>
                    </div>
                   <div className='container'>
                   <div className="row mt-3">
                        <div className="col-lg-8 col-sm-12">
                            {rules &&
                                <div className="card alert-warning">
                                    {rules}
                                </div>
                            }
                        </div>
                    </div>
                   </div>
                </div>
            </div>
            <div className="mt-5"></div>
        </React.Fragment>
    )

}

export default PackDetails;

