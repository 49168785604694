import React, { useEffect, useState } from "react";
import { HeadingService } from "../../../../services/HeadingService";
import PureModal from "react-pure-modal";
import { Carousel } from "react-responsive-carousel";
import { RestoService } from "../../../../services/RestoService";
import { ContactInformation } from "../../../../services/ContactInformation";
import Scroll from "react-scroll-to-element";
import DEV from "../../../../url";

function HeadingRestauration() {
  const heading = new HeadingService();
  const [items_, setItems_] = useState([null]);

  const fetchHeading = () => {
    heading.allRe().then((res) => {
      setItems_(res.data);
      console.log(res.data);
    });
  };

  const [modal, setModal] = useState(false);

  const open = () => {
    setModal(true);
  };

  const close = () => {
    setModal(false);
  };

  const service = new RestoService();

  const [items, setItems] = useState([]);
  const fetch = () => {
    service.getMenuImages().then((res) => {
      setItems(res);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  const contact = new ContactInformation();
  const [rows, setRows] = useState([]);

  const fetchall = async () => {
    contact.all().then((res) => {
      setRows(res);
    });
  };
  useEffect(() => {
    fetchall();
  }, []);

  useEffect(() => {
    fetchHeading();
  }, []);

  return (
    <React.Fragment>
      {items_.map((row, index) => (
        <div
          key={index}
          style={{
            backgroundImage: `url(${DEV}uploads/headings/${row?.img})`,
          }}
          className="page-heading header-text"
        >
          {row?.type_heading === "Restauration" && (
            <div className="container">
              <div className="col-md-12">
                <h1 style={{ fontSize: "6vh", textTransform: "none" }}>
                  Découvrez nos saveurs
                </h1>
                <p
                  style={{
                    fontSize: 16.5,
                    color: "white",
                    wordSpacing: 8,
                    lineHeight: 1.5,
                  }}
                >
                  Découvrez les richesses de la gastronomie locale de Dar
                  Zaghouan grâce à notre cuisine de qualité, savoureuse et
                  exotique
                </p>

                <div className="row mt-4">
                  <div className="col-6" align="right">
                    <button onClick={open} className="filled-btn-avis">
                      <i className="fa fa-eye"></i> Consulter notre menu
                    </button>
                  </div>
                  <div className="col-6" align={"left"}>
                    {rows.map((item_, index_) => (
                      <div key={index_}>
                        {item_.title === "Restaurant" ? (
                                                                                    <a href={`tel:${item_.phonenumber}`} className="filled-btn-avis"> <i className="fa fa-phone mx-2"/>  {item_.phonenumber}</a>

                        ) : (
                          <></>
                        )}
                      </div>
                    ))}
                  </div>

                  <PureModal
                    className={"mt-5 pt-5"}
                    width={600}
                    isOpen={modal}
                    draggable={true}
                  >
                    <div className={"row"}>
                      <div className="col-md-12" align={"right"}>
                        <button
                          onClick={close}
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: 100,
                            border: "1px solid white",
                            backgroundColor: "red",
                            color: "white",
                          }}
                        >
                          <i className={"fa fa-close text-white"}></i>
                        </button>
                      </div>
                    </div>

                    <Carousel className={""}>
                      {items.map((item, index) => (
                        <div key={index} className="col-12">
                           <a
                            target={"_blank"}
                            className="filled-btn-avis"
                            href={`${DEV}uploads/restaurants/${item?.file_src}`}
                            rel="noreferrer"
                          >
                           <i className="fa fa-eye"></i> Agrandir le menu
                          </a>
                            <img
                            className="mt-2"
                              src={`${DEV}uploads/restaurants/${item?.file_src}`}
                              alt=""
                            />
                           
                          
                          
                        </div>
                      ))}
                    </Carousel>
                  </PureModal>

                  <div className="row">
                    <div align={"center"} className="col-12">
                      <div align={"center"} className={"mt-3"}></div>
                    </div>
                  </div>
                </div>
              </div>
              <Scroll style={{ bottom: 0 }} type="id" element="resto">
                <button
                  style={{ marginTop: 0 }}
                  className={"filled-btn-scroll mt-5"}
                >
                  <i className={"fa fa-arrow-down"}></i>
                </button>
              </Scroll>
            </div>
          )}
        </div>
      ))}
    </React.Fragment>
  );
}

export default HeadingRestauration;
