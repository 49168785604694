import React  from "react";
import RoomItems from "./RoomItems";
import RoomsHeading from "./RoomsHeading";

function Rooms() {


    return (
        <React.Fragment>



            <RoomsHeading/>


                <div className="services"  id={"rooms"}>
                    <RoomItems/>
                </div>


        </React.Fragment>
    )
}

export default Rooms;
