import React, { useEffect, useState } from 'react';
import { TermsService } from '../../../../services/TermsService';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from 'dompurify';

function TermsItem(props) {

    const service = new TermsService();
    const [items,setItems]= useState([null]);
    const all = () => {
        service.all().then((res)=>{
            setItems(res);
        })
    }

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [convertedContent, setConvertedContent] = useState(null);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    const createMarkup = (html) => {
        return  {
          __html: DOMPurify.sanitize(html)
        }
    }

    useEffect(()=>{
        all();
    },[]);
    return (
        <div className='container reserver-mobile-center pt-5 mt-5' id={"terms"}>
            <div className='row justify-content-center mt-5 pt-5'>
                {
                    items.map((item,index)=>(
                        <div key={index} className='col-lg-12 col-sm-12'>
                            <h1 className='text-center'>{item?.title} <hr style={{border:'1px solid green',width:150}}/> </h1>
                            <div className='mt-1 row'>
                            <span className=' pt-5 col-sm-12 col-lg-12' dangerouslySetInnerHTML={createMarkup(item?.description)} />
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className='mt-5 pt-5'/>
            
        </div>
    );
}

export default TermsItem;
