import React, {useEffect, useState} from "react";
import {ContactInformation} from "../../../services/ContactInformation";
import {HeadingService} from "../../../services/HeadingService";
//import ConfettiExplosion from 'react-confetti-explosion';
import { useNavigate } from "react-router-dom";
import DEV from "../../../url";

export default function SuccessBooking() {
    const [isExploding, setIsExploding] = React.useState(false);


         const contact = new ContactInformation();
    const[rows,setRows]=useState([]);

     const fetchall = async () => {
        contact.all().then((res) => {
            setRows(res);
        });
    }
    useEffect(() => {
        fetchall();
    }, []);


     const heading = new HeadingService();

      const [items_, setItems_] = useState([]);

    const fetchHeading = () => {
        heading.allRéserver().then((res) => {
            setItems_(res.data);
        })
    }

    useEffect(() => {
        fetchHeading();
    }, []);

    const navigate = useNavigate();

    const gotohome = (e) => {
        e.preventDefault();
        navigate('/')
    }

    return (
        <React.Fragment>
           
            {items_.map((row, index) => (
                <div key={index}>
                  
                    <div 
                     style={{backgroundImage: `url(${DEV}uploads/headings/${row?.img})`}}
                     className="page-heading header-text" >
                         
                    {row?.type_heading === 'Réservation' &&
                    <div className="container" id="container">
                        
                        <div className="row">
                            <div className="col-md-12">
                                <h1 style={{fontSize:'7vh',textTransform:'none'}}>
                                    Chér(e) client(e), vous avez effectuée une demande de réservation</h1>

                                    <div align="center">
                                    <button onClick={gotohome} className="filled-btn-avis mt-2">
                              <i className="fa fa-home"/>  Revenir à l'accueil
                            </button>
                                    </div>
                            </div>
                          
                        </div>
                       
                    </div>
                    }
          </div>
                </div>
                
            ))
            }
        </React.Fragment>
    )
}
