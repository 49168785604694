import React from "react";
import Chiffres from "./Chiffres";
import ContentAboutUs from "./ContentAboutUs";
import Labellisation from "./Labellisation";
import ToutSeTransforme from "./ToutSeTransforme";
import HeadingAbout from "./HeadingAbout";

export default function AboutUs() {

    return (
        <React.Fragment>
            <HeadingAbout/>

            <div className="more-info about-info" id={"histoire"}>
                <h1 className="text-center">Notre Histoire <hr style={{border:'1px solid green',width:200}}/></h1>
                <div className="container reserver-mobile-center">
                    <div className="row">
                        <ContentAboutUs/>
                    </div>
                </div>
            </div>
            <div className="more-info about-info">
                <div className="container">
                    <div className="row">
                        <Labellisation/>
                    </div>
                </div>
            </div>
            <div className="more-info about-info">
                <div className="container ">
                    <div className="row ">
                        <ToutSeTransforme/>
                    </div>
                </div>
            </div> 
            <div className="fun-facts">
                <div className="container">
                    <Chiffres/>
                </div>
            </div>

        </React.Fragment>
    );
}

