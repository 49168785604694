import React, { useEffect, useState } from 'react';
import { ContactInformation } from '../../services/ContactInformation';
import { ChannelService } from "../../services/ChannelService";
import { ConventionService } from '../../services/ConventionService';
import DEV from '../../url';

function ContactInfo() {

    const _ = new ChannelService();
    const service = new ContactInformation();
    const [items, setItems] = useState([]);
    const [rows, setRows] = useState([]);


    useEffect(() => {
        const getData = async () => {
            await _.all().then((res) => {
                setRows(res);
            })
        }
        const fetch = async () => {
            await service.all().then((res) => {
                setItems(res);
            })
        }
        fetch();
        getData();
    }, [null]);





    return (
        <div className="row">
            <div className="col-12">
                <h4>Coordonnées</h4>
            </div>
             {
                items.map((item, index) => (
                    <div key={index} className="col-12">
                        <div className="row">
                            <div className="col-2 ">
                                <span className='contact-icons'>
                                    <i style={{ fontSize: '12px' }} className={`pi ` + item?.icon} aria-hidden="true" />
                                </span>

                            </div>
                            <div className="col-10 mt-1">
                                <small><b>{item.content}</b> <span className={"text-success"}>{item.phonenumber}</span>
                                </small>
                            </div>
                        </div>
                        <div className='mt-2' />
                    </div>
                ))
            } 

            <div className="col-12" style={{ marginTop: -5 }}>
                <ul className="social-icons">
                    {
                        rows.map((row, rowIndex) => (
                            <li key={rowIndex}>
                                {
                                    row.type_chaine === 'Réseau Social' ?

                                        <a rel="nofollow" href={row.link} target="_blank">
                                            <img height={20} width={20} src={`${DEV}uploads/channels/${row?.logo}`} alt="" />
                                        </a>
                                        : <></>

                                }

                            </li>

                        ))
                    }

                </ul>
            </div>
        </div>
    );
}

export default ContactInfo;