import React, {useEffect, useState} from "react";
import {ActivitéService} from "../../../../services/ActivitéService";
import {EventService} from "../../../../services/EventService";
import {ProductService} from "../../../../services/ProductService";
import {RoomsService} from "../../../../services/RoomsService";

export default function Chiffres() {

    const s1 = new ActivitéService();
    const s2 = new EventService();
    const s3 = new ProductService();
    const s4 = new RoomsService();

    const [i1, setI1] = useState([]);
    const [i2, setI2] = useState([]);
    const [i3, setI3] = useState([]);
    const [i4, setI4] = useState([]);

    const f1 = () => {
        s1.getAll().then((res) => {
            setI1(res);
        })
    }

    useEffect(() => {
        f1();
    }, [])

    const f2 = () => {
        s2.getAll().then((res) => {
            setI2(res);
        })
    }

    useEffect(() => {
        f2();
    }, [])

    const f3 = () => {
        s3.all().then((res) => {
            setI3(res);
        })
    }

    useEffect(() => {
        f3();
    }, [])

    const f4 = () => {
        s4.all().then((res) => {
            setI4(res);
        })
    }

    useEffect(() => {
        f4();
    }, [])
    return (
        <div className="row">
            <div className="col-md-6">
                <div className="left-content">
                    <span style={{textTransform:'none'}}>Quelques statistiques</span>
                    <h2>Dar Zaghouan en <em>Chiffres</em></h2>
                    <p>Dar Zaghouan, est un projet pilote qui allie respect du patrimoine naturel, culturel et
                        valorisation des savoirs faire locaux. </p>
                    <p>Dar Zaghouan dispose d'une variété de chambres de luxes, des activités sur site et aux alentours,
                        d'une variété de salles d'événements et des produits artisanaux et de terroir</p>
                </div>
            </div>
            <div className="col-md-6 align-self-center">
                <div className="row">
                    <div className="col-md-6">
                        <div className="count-area-content">
                            <div className="count-digit">{i4?.length}</div>
                            <div className="count-title">Hébérgement</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="count-area-content">
                            <div className="count-digit">{i1?.length}</div>
                            <div className="count-title">Activités</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="count-area-content">
                            <div className="count-digit">{i3?.length}</div>
                            <div className="count-title">Produits</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="count-area-content">
                            <div className="count-digit">{i2?.length}</div>
                            <div className="count-title">Evénementiel</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
