import React from "react";
import HeadingActivities from "./HeadingActivities";
import ItemsActivities from "./ItemsActivities";


function Activities() {
  return (
    <React.Fragment>

      <HeadingActivities/>

      <div className="single-services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
                <ItemsActivities/>
            </div>
            {/*<div className="col-md-4">
              <h4 className="h4 mt-4">Activités Récentes</h4>
                <RecentActivities/>
            </div>*/}
          </div>
        </div>
      </div>
      <br /><br /><br />
    </React.Fragment>
  );

}

export default Activities;

