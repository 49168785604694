import React from "react";


function MapLocation() {
    return(
        <React.Fragment>
            <div id="map">

    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3210.7018186366654!2d10.12173081510571!3d36.416408980029445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd1156d752e04b%3A0x605f81f9f6a4ae6e!2sDar%20Zaghouan!5e0!3m2!1sfr!2stn!4v1661126398472!5m2!1sfr!2stn"
            width="100%" height="500px" style={{border:"0"}} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
</div>
        </React.Fragment>
    );
}

export default MapLocation;
