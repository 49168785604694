import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {EventService} from "../../../../services/EventService";
import HeadingEvent from "./HeadingEvent";
import DEV from "../../../../url";
function DetailsEvent() {



  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");
  const [type, setType] = useState("");

  const [imgs_, setImgs_] = useState([""]);
  const service = new EventService();

  const get = () => {
    service.getbyid(id).then((res) => {
      setTitle(res.title);
      setDescription(res.description);
      setImg(res.img);
      setType(res.type);
    });
  }


  const getImages = () => {
    service.getimagesbyid(id).then((res) => {
      setImgs_(res);
    })
  }


  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    getImages();
  }, []);

  return (
    <React.Fragment>

      <HeadingEvent/>
      <div className="container mt-4" id={"events"}>
        <div className="row">
          <div className="col-md-4 mt-5">
            <h2 className={"mt-3 text-mobile-center"}> Détails : {title}</h2>
            <br/>
            <span className="reserver-mobile-center">Type de cet événement : <b className={"text-success"}>{type}</b></span>

            <div className="alert alert-warning mt-3">
                <p>{description === null ? <span>Pas de contenu disponible pour le moment. <i className={"fa fa-spinner"}></i> </span> : <span>Description : {description}</span> }</p>
            </div>
          </div>
          <div className="col-md-6 last mt-5">
            <br />
           {imgs_.length === 0 ?
              <h1 className="text-center alert alert-warning">Cet événement ne contient pas d'autres images.</h1> : 
                                
              <Carousel useKeyboardArrows={true} infiniteLoop={true} emulateTouch={true} dynamicHeight={true} showArrows={false}>
              {img ? <img alt={title} className="" src={`${DEV}uploads/events/${img}`}  /> : <></>}
              {imgs_.map((item, index) => (
                <div key={index} className="container">
                  <img alt={title} src={`${DEV}uploads/events/${item?.img}`}  />
                </div>
              ))}
            </Carousel> 
          }

          </div>

          {/*<div className="col-md-6">
            <h4 className="h4">{title ? <span>{title}{" "}<Badge type={"danger"} style={{ color: "white" }}>{type}</Badge></span> : <></>}</h4>
            <p className="text-justify mt-4">{description ? <span>{description}</span> : <></>}</p>




            <div className="mt-4">
              <div className="row">
                <div className="col-12">Note</div>
                <div className="col-5">
                  <i style={{ color: "#03AC13" }} className="fa fa-star" />
                  <i style={{ color: "#03AC13" }} className="fa fa-star" />
                  <i style={{ color: "#03AC13" }} className="fa fa-star" />
                  <i style={{ color: "#03AC13" }} className="fa fa-star" />
                  <i style={{ color: "#FFE2E5" }} className="fa fa-star" />
                </div>
              </div>
            </div>


          </div>*/}


        </div>


      </div>

      <div className="mt-5"></div>


    </React.Fragment>
  )
}

export default DetailsEvent;
