import React from "react";
import RoomsItems from "./RoomsItems";

function Vacations(){
    return (

      <React.Fragment>
            <div className="services">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-heading">
                            <h2> <em>Nos chambres</em> d'hôte</h2>
                            <p style={{fontSize:18}} className={"pt-3"}>Êtes-vous attiré par tout ce qui est élégant et raffiné ?  Découvrez nos chambres de luxe haut de gamme</p>
                        </div>
                    </div>
                    <RoomsItems/>
                </div>
            </div>
        </div>
      </React.Fragment>

);
}

export default Vacations;
