import axios from "axios";
import DEV from "../url";

export class RoomsService {
    async all(){
        const res = await axios.get(`${DEV}rooms/all`);
        return res.data;
    }

    async get(id){
        const res = await axios.get(`${DEV}rooms/get/${id}`);
        return res.data;
    }

    async getImages(id){
        const res = await axios.get(`${DEV}rooms/images/` + id);
        return res.data;  
    }
}
