import React, { useEffect, useState } from "react";
import { HeadingService } from "../../../../services/HeadingService";
import Scroll from "react-scroll-to-element";
import DEV from "../../../../url";

function TermsHeading(props) {
  const heading = new HeadingService();

  const [items, setItems] = useState([null]);

  const fetchHeading = () => {
    heading.allRégles().then((res) => {
      setItems(res.data);
    });
  };

  useEffect(() => {
    fetchHeading();
  }, []);
  return (
    <React.Fragment>
      {items.map((row, index) => (
        <div
          key={index}
          style={{
            backgroundImage: `url(${DEV}uploads/headings/${row?.img})`,
          }}
          className="page-heading header-text"
        >
          {row?.type_heading === "Regles utilisation" && (
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 style={{ fontSize: "6vh" }}>
                    Votre sécurité, c'est notre priorité
                  </h1>
                  <p
                    style={{
                      fontSize: 15.5,
                      color: "white",
                      wordSpacing: 8,
                      lineHeight: 1.5,
                      width: "auto",
                    }}
                  >
                    {" "}
                    Veuillez lire attentivement les règles relatives à votre
                    prochain séjour à Dar Zaghouan avant de remplir votre
                    demande de réservation.{" "}
                  </p>
                </div>
              </div>
              <Scroll style={{ bottom: 0 }} type="id" element="terms">
                <button
                  style={{ marginTop: 190 }}
                  className={"filled-btn-scroll"}
                >
                  <i className={"fa fa-arrow-down"}></i>
                </button>
              </Scroll>
            </div>
          )}
        </div>
      ))}
    </React.Fragment>
  );
}

export default TermsHeading;
