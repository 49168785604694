import React from "react";

import MainBanner from "./header/MainBanner";
import Vacations from "./body/Sections/Rooms/Vacations";
import About from "./body/Sections/About";
import Activities from "./body/Sections/Activities/Activities";
import Testimonials from "./body/Sections/Testimonials/Testimonials";


function MainApp(){
    return(
<React.Fragment>

            <MainBanner/>

            <Vacations/>
            <About/>
            <Activities/>
            <Testimonials/>


</React.Fragment>

    );
}


export default MainApp;
