import React, { useState } from "react";
import { ContactService } from "../../services/ContactService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";


export default function Contact() {
  const service = new ContactService();

  const [fullname, setFullname] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");

  const [contact, setContact] = useState(null);

  let empty = {
    fullname: "",
    message: "",
    subject: "",
    email: "",
    phonenumber: "",
  };

  const [submitted, setSubmitted] = useState(false);

  const sendMessage = () => {
    if (
      fullname === "" ||
      message === "" ||
      subject === "" ||
      email === "" ||
      phonenumber === ""
    ) {
      toast.error("Veuillez compléter tous les champs vides.");
      setSubmitted(false);
    } else {
      service
        .contactus({ fullname, message, subject, email, phonenumber })
        .then(() => {
          toast.success("Votre demande a été envoyée avec succées", {
            position: "bottom-right",
            autoClose: 7000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          setFullname("");
          setMessage("");
          setSubject("");
          setEmail("");
          setPhonenumber("");
          setSubmitted(true);
        });
    }
  };

  return (
    <React.Fragment>
      <h4 className={"text-success"}>Contactez-nous</h4>
      <div className="contact-form">
        <form id="contact footer-contact needs-validation" noValidate={false}>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-outline">
                <input
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                  name="fullname"
                  type="text"
                  className="form-control is-invalid"
                  id="fullname"
                  placeholder="Votre nom et prénom"
                  required
                  data-error="Veuillez entrer votre nom complet."
                />
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-outline">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  type="text"
                  className="form-control is-invalid"
                  id="email"
                  pattern="[^ @]*@[^ @]*"
                  placeholder="Votre adresse mail"
                  required
                  data-error="Veuillez entrer votre email."

                />
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-outline">
                <input
                  value={phonenumber}
                  onChange={(e) => setPhonenumber(e.target.value)}
                  name="phonenumber"
                  type="text"
                  className="form-control is-invalid"
                  id="phonenumber"
                  placeholder="Votre numéro de téléphone"
                  required
                  data-error="Veuillez entrer votre numéro de téléphone."

                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-outline">
                <input
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  name="subject"
                  className="form-control is-invalid"
                  id="subject"
                  placeholder="L'objet de votre demande"
                  required
                  data-error="Veuillez entrer votre objet de demande."

                />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-outline">
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  name="message"
                  rows={2}
                  className="form-control is-invalid"
                  id="message"
                  placeholder="Votre message détaillé"
                  required
                  data-error="Veuillez entrer votre message."

                ></textarea>
              </div>
            </div>
             <div className="col-10 form-outline">
              
              <ReCAPTCHA
                className="is-invalid"
                data-error="Veuillez confirmer que vous n'êtes pas un robot."
                sitekey="6Ld99B8kAAAAABPs3JqZQWgSytER1RnKBEDz_xP6"
              />
                  </div> 
            <div className="col-lg-12">
              <fieldset>
                <button
                  onClick={sendMessage}
                  type="submit"
                  id="form-submit"
                  className="filled-btn-avis"
                >
                  Envoyer message
                </button>
              </fieldset>
            </div>

          
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}