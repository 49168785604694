import React, {useEffect, useState} from "react";
import {ContactInformation} from "../../../services/ContactInformation";
import {HeadingService} from "../../../services/HeadingService";
import Scroll from "react-scroll-to-element";
import DEV from "../../../url";

export default function HeadingReserver() {


         const contact = new ContactInformation();
    const[rows,setRows]=useState([]);

     const fetchall = async () => {
        contact.all().then((res) => {
            setRows(res);
        });
    }
    useEffect(() => {
        fetchall();
    }, []);


     const heading = new HeadingService();

      const [items_, setItems_] = useState([]);

    const fetchHeading = () => {
        heading.allRéserver().then((res) => {
            setItems_(res.data);
        })
    }

    useEffect(() => {
        fetchHeading();
    }, []);

    return (
        <React.Fragment>
            {items_.map((row, index) => (
                <div key={index}
                     style={{backgroundImage: `url(${DEV}uploads/headings/${row?.img})`}}
                     className="page-heading header-text">
                    {row?.type_heading === 'Réservation' &&
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 style={{fontSize:'6vh',textTransform:'none'}}>Réservez par téléphone</h1>
                                <div align={"center"} className={"mt-3"}>
                                    {
                                        rows.map((item_, index_) => (
                                            <React.Fragment key={index_}>
                                                {
                                                    item_.title === "Hébérgement" ?
                                                    <a href={`tel:${item_.phonenumber}`} className="filled-btn-avis"> <i className="fa fa-phone mx-2"/>  {item_.phonenumber}</a>

                                                        : <></>
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <span className="mt-4" style={{fontSize:35,textTransform:"none"}}> <b>Ou</b> <hr/> Remplissez ce formulaire</span>
                        <Scroll  type="id" element="reserver">
                                        <button   className={"filled-btn-scroll mt-3"}>
                                            <i className={"fa fa-arrow-down"}></i>
                                        </button>
                        </Scroll>
                    </div>
                    }
          </div>
            ))
            }
        </React.Fragment>
    )
}
