import axios from 'axios';
import DEV from "../url";


export class EventService {

    getAll(){
        return axios.get(`${DEV}event/all`).then((data)=>data.data);
    }

    getbyid(id){
        return axios.get(`${DEV}event/get/${id}`).then((data)=>data.data);
    }

    getimagesbyid(id){
        return axios.get(`${DEV}event/images/${id}`).then((data)=>data.data);
    }
}
