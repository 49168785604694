import React from "react";
import Article from "./Article";

import OnParle from "./OnParle";
import Reportages from "./Reportages";
import VuRéseauxSociaux from "./VuRéseauxSociaux";

export default function OnparledeNousIndex() {

    return (
        <React.Fragment>
            <OnParle/>

            <div id={"onparledenous_"}  className="container" style={{scrollBehavior:'smooth'}}>
                <div  className="more-info about-info">
                    <div className="container">
                        <Reportages/>
                    </div>
                </div>
                <div className="more-info about-info">
                    <div className="container">
                        <VuRéseauxSociaux/>
                    </div>
                </div>
            </div>
            <div className="more-info about-info" align={"center"}>
                <div className="container" align={"center"}>
                            <Article/>
                </div>
            </div>

            <div className="mb-5"></div>
            <div className="mb-5"></div>
            <div className="mb-5"></div>
    



        </React.Fragment>
    );
}

