/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { ConventionService } from "../../services/ConventionService";

import { LiensUtilesService } from "../../services/LiensUtilesService";
import Contact from "./Contact";
import ContactInfo from "./ContactInfo";


function Footer() {

    const service = new LiensUtilesService();
    const today = new Date();
    const cs = new ConventionService();
    const [conventions, setConventions] = useState([null]);
    const [items, setItems] = useState([null]);

    useEffect(() => {
         const fetch = async () => {
            await service.all().then((res) => setItems(res));
        } 
        const fetchConventions = async () => {
            await cs.all().then((res) => {
                setConventions(res);
            })
        }
        fetchConventions();
        fetch();
    }, []);




    return (
        <React.Fragment>
            <footer id={"callbackForm"}>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-3 footer-item">
                            <ContactInfo />
                        </div>

                        <div className="col-md-2 footer-item">
                            <h4>Liens utiles</h4>
                            <ul className="menu-list">
                                 <>
                                    {
                                        items.map((item, index) => (
                                            <li key={index}><a href={item?.link}>{item?.title}</a></li>

                                        ))
                                    }

                                </> 
                                <>
                                    {
                                        conventions.map((row, index_) => (
                                            <li key={index_}><a href={row?.link}>{row?.title}</a></li>

                                        ))
                                    }

                                </>
                            </ul>
                        </div>
                        <div className="col-md-4 reserver-mobile-center">
                            <iframe className="" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3210.7018186366654!2d10.12173081510571!3d36.416408980029445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12fd1156d752e04b%3A0x605f81f9f6a4ae6e!2sDar%20Zaghouan!5e0!3m2!1sfr!2stn!4v1661126398472!5m2!1sfr!2stn"
                                height="400px" style={{ border: "0" }} allowFullScreen="" loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-md-3 footer-item last-item">
                            <Contact />
                        </div>
                    </div>
                </div>
            </footer>

            <div className="sub-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>
                                Copyright © {today.getFullYear()} Dar Zaghouan
                                - Développé par : <a className={"text-success"} href="https://www.ipactconsult.com/"> <b>IPACT Consult inc. </b></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );

}

export default Footer;