import axios from 'axios';
import DEV from "../url";

export class ChannelService {

    all(){
        return axios.get(`${DEV}channel/all`).then((res)=>res.data);
    }



     allchannels(){
        return axios.get(`${DEV}channel/allchannels`).then((res)=>res.data);
    }

     allradios(){
        return axios.get(`${DEV}channel/allradios`).then((res)=>res.data);
    }

     allV(id){
        return axios.get(`${DEV}video/getByChannelID/`+id).then((res)=>res.data);
    }



    videos(){
        return axios.get(`${DEV}video/all`).then((res)=>res.data);
    }
}
