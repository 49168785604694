import React from "react";

function InformationsContact() {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-3">
                    <i className="fa fa-map-marker"></i>
                    <h3>Location</h3>
                    <p>51 Km de la capitale</p>
                </div>
                <div className="col-3">
                    <i className="fa fa-globe"></i>
                    <h3>Couvre </h3>
                    <p>1,8% du territoire national</p>
                </div>
                <div className="col-3">
                    <i className="fa fa-thermometer-half"></i>
                    <h3> Température moyenne </h3>
                    <p> 25°C</p>
                </div>
                <div className="col-3">
                    <i className="fa fa-sun-o"></i>
                    <h3>Climat</h3>
                    <p> Continental, froid et frais en hiver, <br /> chaud et sec en été.</p>
                </div>
            </div>

        </React.Fragment>
    );
}
export default InformationsContact;
