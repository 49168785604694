import React from "react";
import RestaurantItems1 from "./RestaurantItems1";
import RestaurantItems2 from "./RestaurantItems2";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import HeadingRestauration from "./HeadingRestauration";
import Tisanerie from "./Tisanerie/Tisanerie";


function Restaurant() {






    return (

        <React.Fragment>
            <HeadingRestauration/>
            <div className="container mt-5 " align="center" id={"resto"}>
                <div className="row">
                    <div className="col-lg-12 pt-5 mt-3">
                        <div className="section-title pt-5">

                            <h2>Petit déjeuner <hr style={{width:200,backgroundColor:"green"}}/></h2>
                            <span>Uniquement sur réservation d'au moins 24 heures à l'avance.</span>
                        </div>
                    </div>
                </div>
                <RestaurantItems1/>
                <br/><br/>

            </div>

            <RestaurantItems2/>
            <br/><br/>

            <Tisanerie/>
        </React.Fragment>
    );
}

export default Restaurant;
