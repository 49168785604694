import React from "react";
import MapLocation from "./MapLocation";
import ContactForm from "./ContactForm";
import AdditionInformation from "./AdditionInformation";
import InformationsContact from "./InformationsContact";

function ContactPage() {
    return (

        <React.Fragment>
            <div className="page-heading header-text">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Contactez nous</h1>
                            <span>N'hésitez pas à nous envoyer un message maintenant !</span>
                        </div>
                    </div>
                </div>
            </div>

            <AdditionInformation/>

                <div className="callback-form contact-us mt-5">
                    <div className="container">
                        <div className="contact-form">
                            <div className="col-lg-12">
                                <div className="section-heading">
                                    <h2>Des informations sur <em>Dar Zaghouan</em></h2>
                                    <span>Découvrir des informations concernant Dar Zaghouan</span>
                                </div>
                                 <div className="row">
                               
                                <div className="col-lg-12">
                                    <InformationsContact/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MapLocation/>
        </React.Fragment>
    );
}

export default ContactPage;
