import React, {useEffect, useState} from "react";
import logo from "../../assets/images/avatar.png";
import homelogo from "../../assets/images/home.png";
import {LiensUtilesService} from "../../services/LiensUtilesService";


function NavBar() {

    const ls = new LiensUtilesService();
    const [items,setItems]=useState([]);
    const fetchAll = () => {
        ls.all().then((res)=>{
            setItems(res);
        })
    }
    useEffect(()=>{
        fetchAll();
    },[]);

    return (
        <header className="">
            <nav className="navbar navbar-expand-xl" style={{height:110  }}>
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img style={{marginTop:-22,
                            backgroundColor:'white',
                            borderTopRightRadius:100,
                            borderBottomLeftRadius:200,
                            borderBottomLeftRadius:100,
                                    borderBottomRightRadius:100}} src={logo} height={100} width={120} alt="" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav " style={{ marginBottom: 0 }}>
                            <li className="nav-item dropdown">
                                <a className="dropdown-toggle nav-link" data-toggle="dropdown" href="#" role="button"
                                    aria-haspopup="true" aria-expanded="false">
                                    <img src={homelogo} width={25} height={25} alt="" />
                                </a>
                                <div className="dropdown-menu text-success">
                                    <a className="dropdown-item " href="/onParledeNous" style={{scrollBehavior:'smooth'}}>
                                         <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> On parle de nous</a>
                                     <a className="dropdown-item " href="/apropos">
                                         <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> A propos</a>
                                    <a className="dropdown-item " href="/terms">
                                         <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> Régles d'accueil</a>
                                    {
                                        items.map((item,index)=>(
                                            <div key={index}>
                                                {
                                                    item.title === 'Galerie photos' &&
                                                        <a target={"_blank"} className="dropdown-item " href={item.link}>
                                                            <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> Galerie photos</a>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="/rooms">
                                    <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> Hébérgement</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="/restaurant">
                                   <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> Restauration</a>
                            </li>
                             <li className="nav-item">
                                <a className="nav-link " href="/hammametspa">
                                   <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> Hammam & Spa</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="/packs">
                                    <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> Packs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="/activities">
                                    <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> Activités</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="/products">
                                    <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> Terroirs</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link " href="/events">
                                    <i style={{fontSize:10}} className={"fa fa-circle text-success"}></i> Evénements</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default NavBar;
