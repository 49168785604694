import React from "react";
import TermsHeading from "./TermsHeading";
import TermsItem from "./TermsItem";

function Terms() {


    return (
        <React.Fragment>

            <TermsHeading/>

           <TermsItem/>

        </React.Fragment>
    );
}

export default Terms;
