import React, {useEffect, useState} from 'react'
import {HeadingService} from "../../../../services/HeadingService";
import Scroll from 'react-scroll-to-element';
import DEV from '../../../../url';

function HeadingAbout() {
    const heading = new HeadingService();

    const [items, setItems] = useState([null]);

    const fetchHeading = () => {

        heading.allOnraconte().then((res) => {
            setItems(res.data);
        })
    }

    useEffect(() => {
        fetchHeading();
    }, []);


    return (
        <React.Fragment>
            {
                items.map((row, index) => (
                    <div key={index}
                         style={{backgroundImage: `url(${DEV}uploads/headings/${row?.img})`}}
                         className="page-heading header-text">
                        {
                            row?.type_heading === 'On raconte pour vous' &&
                            <div className="container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h1 style={{fontSize: '6vh',textTransform:'none'}}>Plus de 15 ans d'expérience</h1>
                                            <p style={{
                                                fontSize: 16.5,
                                                color: "white",
                                                wordSpacing: 8,
                                                lineHeight: 1.5
                                            }}>Des échanges riches et précieux, un partage d’expériences. <br/> On parle le même langage, on porte les mêmes valeurs.</p>
                                        </div>
                                    </div>
                                </div>
                                <Scroll style={{bottom: 0}} type="id" element="histoire">
                                        <button   className={"filled-btn-scroll mt-5"}>
                                            <i className={"fa fa-arrow-down"}></i>
                                        </button>
                                </Scroll>
                            </div>
                        }
                    </div>
                ))
            }


        </React.Fragment>
    )
}

export default HeadingAbout;
