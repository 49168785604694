import React from 'react';
import LaisserCommentaireForm from './LaisserCommentaireForm';
import LaisserCommentaireHeading from './LaisserCommentaireHeading';

function LaisserCommentaire() {
    return (
        <React.Fragment>
                <LaisserCommentaireHeading/>
                <LaisserCommentaireForm/>
        </React.Fragment>
    );
}

export default LaisserCommentaire;