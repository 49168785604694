import React, { useState, useEffect } from "react";

import IconDejeuner from "../../../../assets/images/icondej.png";
import { RestoService } from "../../../../services/RestoService";
import DEV from "../../../../url";


function RestaurantItems2() {

    const service = new RestoService();

    const [items, setItems] = useState([]);

    const fetch = () => {
        service.all().then((res) => {
            setItems(res);
        });
    }
    useEffect(() => {
        fetch();
    }, []);

    return (<div className="container mt-5  ">
        <div className="row" align="center">
            <div className="col-lg-12">
                <div className="section-title">

                    <h2>Déjeuner <hr style={{width:200,backgroundColor:"green"}}/></h2>
                    <span>Uniquement sur réservation d'au moins 24 heures à l'avance.</span>
                </div>
            </div>
        </div>
        <div className="container mt-5">
            <div className="row last">
                {
                    items.map((item, index) => (
                        <React.Fragment key={index}>
                            {
                                item.type === 'Déjeuner' ?
                                    <div className="col-sm-6 col-lg-4 mb-2-6 mb-lg-0 ">
                                        <div className="card-wrapper mb-4">
                                            <div className="card-img"><img width={350} height={300} src={`${DEV}uploads/restaurants/${item?.img}`} alt="..." />
                                            </div>
                                            <div className="card-body">
                                                <div>
                                                    <img width={140} src={IconDejeuner} alt={item.title} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="row">
                                               <div className="col-lg-7" >
                                                    <p style={{fontSize:17}} className=" mb-2"><a href="#" className="text-secondary">  <b>{item.title}</b></a></p>
                                               </div>

                                               <div className="col-lg-5">
                                                   {
                                                       item.price === 0 ? <></> :
                                                           <React.Fragment>
                                                                <small className={"text-success"}><b>A partir de</b> </small> <p className={"text-success "}><b>{item.price}{" DT / Personne "}</b></p>
                                                           </React.Fragment>
                                                   }
                                               </div>
                                           </div>
                                            <div className="product-rating mb-2 mt-2">
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                        <a href={`/lunch-details${item.id}`} className="filled-btn-avis mt-2">
                                                            
                                                                Voir détails
                                                            
                                                        </a>
                                                    </div>
                                                 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-5 "></div>
                                    </div> : <></>
                            }

                        </React.Fragment>
                    ))
                }



            </div>
        </div>
        <br /><br />

    </div>);
}
export default RestaurantItems2;
