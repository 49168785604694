import axios from 'axios';
import DEV from "../url";

export class TermsService{

    async all(){
        const res = await axios.get(`${DEV}terms/all`);
        return res.data.result;
    }
}
