import React, { useEffect, useState } from "react";
import { RoomsService } from "../../../../services/RoomsService";
import DEV from "../../../../url";

function RoomsItems() {

    const service = new RoomsService();
    const [items, setItems] = useState([null]);
    const fetch = () => {
        service.all().then(res => {
            setItems(res);
        });
    }

    useEffect(() => {
        fetch();
    }, []);
    return (

        <section>
            <div className="row last">

                        {
                            items.slice(0,4).map((item, index) => (
                                <div className="col-md-3 col-sm-12 last" key={index}>
                                    <a href={`/room-${item?.id}`} className="card_">
                                        <div className="content last">
                                                <img style={{
                                                    height:370,
                                                    width : 280,
                                                    borderTopRightRadius:25,
                                                    borderBottomLeftRadius:25,
                                                    justifyContent:'center',
                                                    justifyItems:'center'
                                                }} alt="img-chambre" src={`${DEV}uploads/rooms/${item?.img}`} />

                                        </div>
                                        <ul className="sci">
                                           {
                                            item?.price === (0||'') ?
                                            <li style={{ height: 60, width: 220, borderTopLeftRadius: 20, borderBottomRightRadius: 20 }} className="bg-white">
                                            <h5 className="text-success text-center mt-3">
                                                <b>{item?.title}</b> <br /></h5>

                                        </li>:
                                         <li style={{ height: 100, width: 250, borderTopLeftRadius: 20, borderBottomRightRadius: 20 }} className="bg-white">
                                         <h5 className="text-success text-center mt-3">
                                             <b>{item?.title}</b> <br /></h5>
                                             <h6 className="mt-3 text-center text-success">  {item?.price}{" DT / Personne / Nuit"}</h6>

                                     </li>
                                           }
                                            

                                        </ul>
                                    </a>
                                </div>
                            ))
                        }


            </div>
            <div align={"center"} className={"mt-5"}>
                        <a  href={"/rooms"} className="filled-btn-avis">
                            <span> Voir plus</span>
                        </a>
                    </div>

        </section>
    );
}

export default RoomsItems;
