import React, { useEffect, useState } from "react";
import { ProductService } from "../../../../services/ProductService";
import IconProduits from "../../../../assets/images/iconProduits.png";
import DEV from '../../../../url';

function ProductItems() {
  const service = new ProductService();
  const [items, setItems] = useState([]);
  const [searchitem, setSearchitem] = useState("");
  const fetch = () => {
    service.all().then((res) => {
      setItems(res);
    });
  };
  useEffect(() => {
    fetch();
  }, []);
  return (
    <React.Fragment>
      <div className="container mt-5" id={"terroir"}>
        <div className=" pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <select
                  selected="0"
                  className="form-control"
                  style={{
                    height: 50,
                    borderTopRightRadius: 20,
                    borderBottomLeftRadius: 20,
                  }}
                  name="searchitem"
                  onChange={(e) => setSearchitem(e.target.value)}
                >
                  <option disabled value="0">
                    Sélectionner votre type de terroir
                  </option>
                  <option value="" onClick={fetch}>
                    Tous les types de terroirs
                  </option>
                  <option value="Fromages & Létiers">Fromages & Létiers</option>
                  <option value="Kaak Warka">Kaak Warka</option>
                  <option value="Olive & Huile Olive">
                    Olive & Huile Olive
                  </option>
                  <option value="Tabouna">Tabouna</option>
                  <option value="Bonbons & Halkoum">Bonbons & Halkoum</option>
                  <option value="Plantes médicinales">
                    Plantes médicinales
                  </option>
                </select>
              </div>
            </div>
            <div className="row last mt-4">
              {items
                .filter((item_) => {
                  if (searchitem === "") {
                    return item_;
                  } else if (
                    item_.type.toLowerCase().includes(searchitem.toLowerCase())
                  ) {
                    return item_;
                  }
                })
                .map((item, index) => (
                  <div className="col-md-4" key={index}>
                    <div className="card-wrapper">
                      <div className="card-img">
                        <img
                          height={260}
                          src={`${DEV}uploads/products/${item?.img}`}
                          alt={item.title}
                          className="img-responsive"
                        />
                      </div>
                      <div className="card-body">
                        <div>
                          <img
                            height={100}
                            src={IconProduits}
                            alt={item.title}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-7">
                        <div className="title-product mt-3">
                          <h6
                            style={{ fontSize: 17 }}
                            className="text-secondary mb-2"
                          >
                            {item.title}
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        
                          {item.price == 0 ? (
                            <></>
                          ) : (
                            <p>
                              <b className={"text-success"}>
                                {" A partir de "} {item.price} {"DT / Personne "}
                              </b>
                            </p>
                          )}
                        
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className={"col-12"} align={"center"}>
                        <a
                          href={`product-details-${item.id}`}
                          className="filled-btn-avis"
                        >
                          Voir Détails
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5" />
    </React.Fragment>
  );
}

export default ProductItems;
