import React, { useEffect, useState } from 'react';
import { ContactInformation } from '../../services/ContactInformation';
import { ChannelService } from "../../services/ChannelService";
import DEV from '../../url';

export default function NavInfo() {

  const _ = new ChannelService();
  const [rows, setRows] = useState([]);

  const service = new ContactInformation();
  const [items, setItems] = useState([]);



  useEffect(() => {
    const fetch_ = async () => {
      await _.all().then((res) => {
        setRows(res);
      })
    }
    const fetch = async () => {
      await service.emailAndRenseignement().then((res) => {
        setItems(res);
      })
    }
    fetch_();
    fetch();
  }, [null]);

  // useEffect(() => { }, [rows, items]);





  return (

    <div className="sub-header">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <ul className="left-info">

              <li>

                <a href={`mailto:darzaghouan@gmail.com`}>
                  <i className={`pi pi-envelope`}></i>
                  darzaghouan@gmail.com
                </a>
                </li>

              <li>
                <a href={`tel:+21623724000`}>
                  <i className={`pi pi-phone`}></i>
                  Renseignement &nbsp; +216 23 724 000
                </a>

              </li>




            </ul>
          </div>
          <div className="col-md-6">
            <ul className="right-icons">
              {
                rows.map((row, rowIndex) => (
                  <li key={rowIndex}>
                    {
                      row.type_chaine === 'Réseau Social' ?

                        <a rel="nofollow" href={row.link} target="_blank">
                          <img height={20} width={20} src={`${DEV}uploads/channels/${row?.logo}`} alt="" />
                        </a>
                        : <></>

                    }

                  </li>

                ))
              }

            </ul>
          </div>
        </div>
      </div>
    </div>);
}
