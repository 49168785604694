import React from "react";

function ContactForm() {
    return(
        <React.Fragment>

                    <form id="contact" action="" method="get">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <fieldset>
                                    <input name="name" type="text" className="form-control" id="name"
                                           placeholder="Nom complet" required=""/>
                                </fieldset>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <fieldset>
                                    <input name="email" type="text" className="form-control" id="email"
                                           pattern="[^ @]*@[^ @]*" placeholder="E-Mail Address" required=""/>
                                </fieldset>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <fieldset>
                                    <input name="subject" type="text" className="form-control" id="subject"
                                           placeholder="Sujet" required=""/>
                                </fieldset>
                            </div>
                            <div className="col-lg-12">
                                <fieldset>
                                    <textarea name="message" rows="6" className="form-control" id="message"
                                              placeholder="Votre Message" required=""></textarea>
                                </fieldset>
                            </div>
                            <div className="col-lg-12">
                                <fieldset>
                                    <button type="submit" id="form-submit" className="filled-button">Envoyez Message</button>
                                </fieldset>
                            </div>
                        </div>
                    </form>

    </React.Fragment>);
}
export default ContactForm;
