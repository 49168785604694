/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./stepper.css";
import { BookingService } from "../../../services/BookingService";

import Countries from "../../../services/countries.json";
import HeadingReserver from "./HeadingReserver";
import { ConventionService } from "../../../services/ConventionService";
import "react-step-progress/dist/index.css";
import ReCAPTCHA from "react-google-recaptcha";

import TermsItem from "./Terms/TermsItem";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Collapsible from "react-collapsible";

const Reserver = () => {
  const navigate = useNavigate();

  const booking = new BookingService();

  const [single, setSingle] = useState("");
  const [double_, setDouble_] = useState("");
  const [triple, setTriple] = useState("");
  const [quadriple, setQuadriple] = useState("");

  const [qte_single, setQte_single] = useState("");
  const [qte_double, setQte_double] = useState("");
  const [qte_triple, setQte_triple] = useState("");
  const [qte_quadriple, setQte_quadriple] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [nb_adultes, setNb_adultes] = useState("");
  const [nb_enfants, setNb_enfants] = useState("");
  const [nb_bébés, setNb_bébés] = useState("");

  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [fullname, setFullname] = useState("");
  const [date_booking, setDate_booking] = useState("");
  const [phonenumber, setPhonenumber] = useState("" | undefined);
  const [email, setEmail] = useState("");
  const [days, setDays] = useState("");

  const [end_date, setEnd_date] = useState("");


  //const [isVisible, setIsVisible] = useState(false);

  const handleCountry = (e) => {
    setCountry(e.target.value);

    if (!e.target.value.includes("Tunisia")) {
      toast.success(
        "Veuillez cliquer sur l'un des liens externes ci dessus pour poursuivre votre réservation. "
      );
    } else {
    }
  };

  const send = (e) => {
    e.preventDefault();

  
      if (
        !isChecked ||
        title.length === 0 ||
        nb_adultes === 0 ||
        fullname.length === 0 ||
        email.length === 0 ||
        phonenumber === "" ||
        date_booking > end_date
      ) {
        toast.error("Veuillez remplir soigneusement les champs manquants du formulaire pour soumettre votre demande. ");
      }
     

     else {
      booking
        .bookARoom({
          title,
          single,
          qte_single,
          double_,
          qte_double,
          triple,
          qte_triple,
          quadriple,
          qte_quadriple,
          description,
          nb_adultes,
          nb_enfants,
          nb_bébés,
          address,
          country,
          email,
          fullname,
          phonenumber,
          date_booking,
          end_date,
          days,
        })
        .then(() => {
          toast.success("Votre demande a été envoyée avec succées. ");

          setIsChecked(true);

          navigate("/success");
        });
    }
  };

  const cs = new ConventionService();

  const [conventionstn, setConventionstn] = useState([]);

  const fetchConventionstn = () => {
    cs.allTn().then((res) => {
      setConventionstn(res);
    });
  };

  const [conventionsnottn, setConventionsnottn] = useState([]);

  const fetchConventionsNottn = () => {
    cs.allNotTn().then((res) => {
      setConventionsnottn(res);
    });
  };

  useEffect(() => {
    fetchConventionstn();
    fetchConventionsNottn();
  }, []);

  const button = () => {
      return (<button type="">+</button>)
  }

  const [isChecked, setIsChecked] = useState(false);

  return (
    <React.Fragment>
      <HeadingReserver />

      <div className="container mt-3" id={"reserver"}>
        <div className="row pt-3">
          <div className="col-md-12 col-sm-12 mt-5">
            <h1 className={"text-center mt-5"}>
              Réservez votre place{" "}
              <hr style={{ border: "1px solid green", width: 200 }} />
            </h1>
            <br />
            <br />
            <form
             id="needs-validation " noValidate={false}

            >
              <div align="center" className="alert alert-success mt-3 col-12">
                <div>
                  Chér(e) client(e), <br />
                  {/*    <br/> Si vous êtes en Tunisie, vous pouvez effectuer votre réservation via <a
                                                href={link__}><b className={"text-success"}>Tunisie Booking</b></a>
                                            ou bien <a href={link___}><b className={"text-success"}>Tunisie Promo</b></a>. */}
                  Si vous êtes en Tunisie, vous pouvez réserver votre place en
                  cliquant sur l'un des liens suivant
                  <>
                    {conventionstn.map((item, index) => (
                      <a key={index} href={item.link}>
                        <b className={"text-success"}>
                          <br />
                          {item.title}{" "}
                        </b>
                      </a>
                    ))}
                  </>
                  <br /> <hr style={{ border: "0.5px solid green" }} />{" "}
                  <div className="mt-1">
                    Si vous êtes dans un autre pays, vous pouvez réserver votre
                    place en cliquant sur l'un des liens suivant
                  </div>
                  <>
                    {conventionsnottn.map((item, index) => (
                      <a key={index} href={item.link}>
                        <b className={"text-success"}>
                          <br />
                          {item.title}
                        </b>
                      </a>
                    ))}
                  </>
                </div>
              </div>
              <label htmlFor="" className={"col-12 mt-4"} align={"center"}>
                Dans quel pays résidez-vous ?
              </label>
              <div className="row ">
                <div className="col-12">
                  <select
                    value={country}
                    onChange={handleCountry}
                    style={{
                      border: "1px solid green",
                      height: 60,
                      marginLeft: 0,
                    }}
                    name="country"
                    id="country"
                    className="form-control"
                  >
                    {Countries.countries.map((item, index) => (
                      <option key={index} value={item.country_name}>
                        {item.country_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-12" align={"center"}>
                  {/*  <button
                    onClick={handleCountry}
                    className={"filled-btn-avis mx-3"}
                  >
                    <i className={"fa fa-arrow-right"}>
                      &nbsp; Poursuivre le process
                    </i>
                  </button> */}
                </div>
                <div className="row mx-2" align={"center"}>
                  {/*style={{visibility: country === 'Tunisia' ? 'visible' : 'hidden'}}*/}
                </div>
              </div>

              <br />

              <div
                style={{
                  visibility: country === "Tunisia" ? "visible" : "hidden",
                }}
              >
                <div className="row   mt-5" style={{borderRadius:5}}>
                  
                  <div className="col-12">
                    <Collapsible
                      open={true}
                      trigger={
                        `       1- Informations concernant la demande de réservation`
                      }
                      triggerClassName="hoverCollapse fa fa-plus"
                      triggerStyle={{ fontSize: 40, cursor:'pointer',padding:20 }}
                    >
                      <hr />

                      <div className="bg-light container">
                        <div
                          className="row mt-5"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-lg-1 col-sm-12">
                            <i className="fa fa-plus text-success" hidden></i>
                          </div>

                          <div className="col-lg-4 col-sm-12 mt-4">
                            <label htmlFor="">Type de chambre</label>
                            <select
                              selected="Single"
                              defaultValue="Single"
                              defaultChecked="Single"
                              disabled
                              onChange={(e) => setSingle(e.target.value)}
                              style={{
                                border: "1px solid green",
                                height: 60,
                                marginLeft: 0,
                              }}
                              name="type_chalet"
                              id="type_chalet"
                              className="form-control mt-4"
                            >
                              <option value="Single">Single</option>
                            </select>
                          </div>
                          <div className="col-lg-2 col-sm-12">
                            <label className={"col-12"} htmlFor="" style={{paddingTop:25}}>
                              Nombre de chambre(s)
                            </label>

                            <select
                              selected="0"
                              defaultValue="0"
                              defaultChecked="0"
                              onChange={(e) => setQte_single(e.target.value)}
                              style={{
                                border: "1px solid green",
                                height: 60,
                                marginLeft: 0,
                              }}
                              name="single"
                              id="single"
                              className="form-control"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>
                        <div
                          className="row  mt-3"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-lg-1 col-sm-1">
                            <i className="fa fa-plus text-success"></i>
                          </div>
                          <div className="col-lg-4  col-sm-12 last">
                            <select
                              disabled
                              selected="Double"
                              defaultValue="Double"
                              defaultChecked="Double"
                              onChange={(e) => setDouble_(e.target.value)}
                              style={{
                                border: "1px solid green",
                                height: 60,
                                marginLeft: 0,
                              }}
                              name="double_"
                              id="double_"
                              className="form-control"
                            >
                              <option value="Double">Double</option>
                            </select>
                          </div>
                          <div className="col-lg-2  col-sm-12">
                            <select
                              selected="0"
                              defaultValue="0"
                              defaultChecked="0"
                              onChange={(e) => setQte_double(e.target.value)}
                              style={{
                                border: "1px solid green",
                                height: 60,
                                marginLeft: 0,
                              }}
                              name="qte_double"
                              id="qte_double"
                              className="form-control"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>

                        <div
                          className="row last mt-3"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-lg-1  col-sm-12">
                            <i className="fa fa-plus text-success"></i>
                          </div>
                          <div className="col-lg-4  col-sm-12">
                            <select
                              disabled
                              selected="Triple"
                              defaultValue="Triple"
                              defaultChecked="Triple"
                              onChange={(e) => setTriple(e.target.value)}
                              style={{
                                border: "1px solid green",
                                height: 60,
                                marginLeft: 0,
                              }}
                              name="triple"
                              id="triple"
                              className="form-control"
                            >
                              <option value="Triple">Triple</option>
                            </select>
                          </div>
                          <div className="col-lg-2  col-sm-12">
                            <select
                              selected="0"
                              defaultValue="0"
                              defaultChecked="0"
                              onChange={(e) => setQte_triple(e.target.value)}
                              style={{
                                border: "1px solid green",
                                height: 60,
                                marginLeft: 0,
                              }}
                              name="qte_triple"
                              id="qte_triple"
                              className="form-control"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>

                        <div
                          className="row last mt-3"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="col-lg-1 col-sm-1">
                            <i className="fa fa-plus text-success"></i>
                          </div>
                          <div className="col-lg-4  col-sm-12">
                            <select
                              selected="Quadriple"
                              defaultValue="Quadriple"
                              defaultChecked="Quadriple"
                              disabled
                              onChange={(e) => setQuadriple(e.target.value)}
                              style={{
                                border: "1px solid green",
                                height: 60,
                                marginLeft: 0,
                              }}
                              name="quadriple"
                              id="quadriple"
                              className="form-control"
                            >
                              <option value="Quadriple">Quadriple</option>
                            </select>
                          </div>
                          <div className="col-lg-2  col-sm-12">
                            <select
                              selected="0"
                              defaultValue="0"
                              defaultChecked="0"
                              onChange={(e) => setQte_quadriple(e.target.value)}
                              style={{
                                border: "1px solid green",
                                height: 60,
                                marginLeft: 0,
                              }}
                              name="title"
                              id="title"
                              className="form-control"
                            >
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </div>
                        </div>

                        <div className="">
                          <label className="text-left mt-4">
                            Objet de la réservation
                          </label>
                          <div className="form-outline">
                            
                          
                          <select
                            selected=""
                            onChange={(e) => setTitle(e.target.value)}
                            style={{
                             border: "1px solid green",
                              height: 60,
                              marginLeft: 0,
                            }}
                            name="title"
                            id="title"
                            className="form-control is-invalid"
                            required={true}
                            data-error="Veuillez entrer l'objet de votre réservation."
                          >
                            <option value="" >La raison de votre séjour</option>
                            <option value="Tourisme & Loisir">
                              Tourisme / Loisir
                            </option>
                            <option value="Travail & Professionnel">
                              Travail / Professionnel
                            </option>
                            <option value="Formation & Conférence">
                              Formation / Conférence
                            </option>
                            <option value="Voyage de noce &  Anniversaire de mariage">
                              Voyage de noce / Anniversaire de mariage
                            </option>

                            <option value="Anniversaire & Célébration Familiale">
                              Anniversaire / Célébration Familiale
                            </option>
                            <option value="Autre">Autre </option>
                          </select>
                          </div>
                        </div>
                        <br />
                        <br />

                        <div className="row">
                          <div className="col-lg-4  col-sm-12">
                            <div className="row form-outline">
                              <div className="col-12">
                                <label htmlFor="">
                                  Nombre d'adultes (Plus de 12 ans)
                                </label>
                              </div>
                              <div className="col-12 form-outline">
                                <select
                                  selected="0"
                                  onChange={(e) =>
                                    setNb_adultes(e.target.value)
                                  }
                                  style={{
                                    border: "1px solid green",
                                    height: 60,
                                    marginLeft: 0,
                                  }}
                                  name="nb_adultes"
                                  id="nb_adultes"
                                  className="form-control is-invalid"
                                  data-error="Veuillez sélectionner votre nombre."
                                >
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4  col-sm-12">
                            <div className="row">
                              <div className="col-12">
                                <label htmlFor="">
                                  Nombre d'enfants (entre 3 et 12 ans)
                                </label>
                              </div>
                              <div className="col-12 form-outline">
                                <select
                                  selected="0"
                                  onChange={(e) =>
                                    setNb_enfants(e.target.value)
                                  }
                                  style={{
                                    border: "1px solid green",
                                    height: 60,
                                    marginLeft: 0,
                                  }}
                                  name="nb_enfants"
                                  id="nb_enfants"
                                  className="form-control"
                                >
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4  col-sm-12">
                            <div className="row">
                              <div className="col-12">
                                <label htmlFor="">
                                  Nombre de bébés (moins de 2 ans)
                                </label>
                              </div>
                              <div className="col-12">
                                <select
                                  selected="0"
                                  onChange={(e) => setNb_bébés(e.target.value)}
                                  style={{
                                    border: "1px solid green",
                                    height: 60,
                                    marginLeft: 0,
                                  }}
                                  name="nb_bébés"
                                  id="nb_bébés"
                                  className="form-control"
                                >
                                  <option value="0">0</option>
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <label className={"col-12 mt-4"} htmlFor="">
                          Autres informations
                        </label>
                        <textarea
                          style={{ border: "1px solid green" }}
                          name={"description"}
                          onChange={(e) => setDescription(e.target.value)}
                          rows={120}
                          className="form-control-lg w-100"
                        ></textarea>
                        <br />
                        <br />
                        <div className="row">
                          <div className="form-outline col-lg-6  col-sm-12">
                            <label className={"col-12"} htmlFor="">
                              Date d'arrivée (Check-in)
                            </label>
                            <input
                              style={{ border: "1px solid green", height: 60 }}
                              className={"form-control-lg w-100 is-invalid"}
                              type="date"
                              onChange={(e) => setDate_booking(e.target.value)}
                              name={"date_booking"}
                              required
                              data-error="Veuillez entrer votre date d'arrivée."
                            />
                          </div>
                          <div className="form-outline col-lg-6  col-sm-12">
                            <label className={"col-12"} htmlFor="">
                              Date de départ (Check-out)
                            </label>
                            <input
                              style={{ border: "1px solid green", height: 60 }}
                              className={"form-control-lg is-invalid w-100"}
                              type="date"
                              onChange={(e) => setEnd_date(e.target.value)}
                              name={"end_date"}
                              required
                              data-error="Veuillez entrer votre date de départ."
                            />
                          </div>
                        </div>
                        <br/>
                      </div>

                      
                    </Collapsible>
                  </div>
                </div>
                <div className="row mt-5 w-100" style={{borderRadius:5}}>
                  
                  <div className="col-12 pt-4">
                  
                    <Collapsible
                      trigger={"      2- Informations concernant le client"}
                      triggerClassName="hoverCollapse fa fa-plus"
                      triggerStyle={{ fontSize: 42, cursor:'pointer',padding:20,width:900 }}
                    >
                      <div className=""></div>
                      <hr />

                   <div className="container bg-light">
                   <div className="row">
                        <div className="col-lg-3  col-sm-12 mt-3 form-outline">
                          <label className={"col-12"} htmlFor="">
                            Au nom de
                          </label>
                          <input
                            style={{ border: "1px solid green", height: 60 }}
                            type={"text"}
                            name={"fullname"}
                            onChange={(e) => setFullname(e.target.value)}
                            className="form-control-lg is-invalid w-100"
                            data-error="Veuillez entrer votre nom et prénom."

                          />
                        </div>
                        <div className="col-lg-6 col-sm-12 form-outline">
                          <label className={"col-12 mt-3"} htmlFor="">
                            Adresse E-mail
                          </label>
                          <input
                            style={{ border: "1px solid green", height: 60 }}
                            type={"email"}
                            name={"email"}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control-lg is-invalid w-100"
                            data-error="Veuillez entrer votre adresse email."

                          />
                        </div>
                        <div className="col-lg-3 col-sm-12 mt-3 form-outline">
                          <label className={"col-12"} htmlFor="">
                            Numéro de téléphone
                          </label>

                          <PhoneInput
                            inputStyle={{
                              height: 60,
                              border: "1px solid green",
                              width: "auto",
                            }}
                            buttonStyle={{ border: "1px solid green" }}
                            country={"tn"}
                            value={phonenumber || ""}
                            onChange={setPhonenumber}
                            containerClass="is-invalid"
                            data-error="Veuillez entrer votre numéro de téléphone."
                            

                          />
                        </div>
                      </div>
                      <div className="form-outline">
                        <label className={"col-12 mt-3"} htmlFor="">
                          Adresse de résidence
                        </label>
                        <input
                          style={{ border: "1px solid green", height: 60 }}
                          name={"address"}
                          onChange={(e) => setAddress(e.target.value)}
                          className="form-control-lg is-invalid w-100"
                        />
                        <br />
                        <br />
                      </div>

                   </div>
                      <br />
                      <br />
                    </Collapsible>
                  </div>
                </div>

                <div className="row">
                  

                  <div className="col-12 mt-5">
                

                    <Collapsible
                      trigger={"     3- Soumission de votre demande de réservation"}
                      triggerClassName="hoverCollapse fa fa-plus"
                      triggerStyle={{ fontSize: 42, cursor:'pointer', padding:20 }}
                     
                    >
                      <hr />
                      <div align={"center"} className="bg-light">
                        <div className="row">
                          <div className="col-12 mt-5">
                            <div
                              className="card card-body w-75"
                              style={{
                                height: 300,
                                border: "1px solid green",
                                overflow: "scroll",
                              }}
                            >
                              <TermsItem />
                            </div>
                            <div className="mt-4 text-danger form-outline">
                              Acceptez-vous les régles d'accueil ci dessus ?
                              <input
                                value={isChecked}
                                onChange={(e) => setIsChecked(e.target.value)}
                                type={"checkbox"}
                                className="form-control is-invalid pt-3"
                                data-error="Veuillez accepter les régles d'accueil."

                              />
                            </div>
                            <p className="mt-4 text-center">
                              Je ne suis pas un Robot !
                            </p>
                            <ReCAPTCHA
                              data-error="Veuillez confirmer que vous n'êtes pas un robot."
                              sitekey="6Ld99B8kAAAAABPs3JqZQWgSytER1RnKBEDz_xP6"
                            />
                          </div>
                        </div>

                        <button  onClick={send} className="filled-btn-avis mt-4 mb-4">
                          Envoyer Maintenant
                        </button>
                      </div>

                      <br />
                      <br />
                    </Collapsible>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <div className="mt-5"></div>
        <div className="mt-5"></div>
      </div>
    </React.Fragment>
  );
};

export default Reserver;
