import React, { useEffect, useState } from "react";
import { ChannelService } from "../../../../services/ChannelService";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DEV from '../../../../url';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function Reportages() {
  const _ = new ChannelService();
  const [items, setItems] = useState([]);

  const allch = () => {
    _.allchannels().then((res) => {
      setItems(res);
    });
  };

  useEffect(() => {
    allch();
  }, []);
  return (
    <div style={{ paddingTop: 30 }} id={"onparledenous"}>
      <div align="center" className="more-info-content pt-5 mt-5">
        <h1>
          <b> Télévisions </b>
        </h1>
      </div>

      <div className="row">
        <div className="col-12" align="center">
          <Carousel
            arrows={true}
            align={"center"}
            className={"w-75 mt-5"}
            draggable={true}
            responsive={responsive}
          >
            {items.map((item, index) => (
              <div key={index} className="col-lg-2 col-md-6 col-sm-12">
                <a target={"_blank"} className={"text-center"} href={item.link}>
                  <img
                    alt=""
                    width={150}
                    height={120}
                    src={`${DEV}uploads/channels/${item?.logo}`}
                  />
                </a>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Reportages;
