import React, { useState } from "react";
import { toast } from "react-toastify";
import Modal from "react-modal";
import classNames from "classnames";

import TestimonialsItems from "./TestimonialsItems";
import { FeedbackService } from "../../../../services/FeedbackService";
import ReCAPTCHA from "react-google-recaptcha";

const customStyles = {
  content: {
    width: "50%",
    height: "70%",
    top: "60%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
};

function Testimonials() {
  const service = new FeedbackService();

  const date = new Date().getDate() + "";

  const [fullname, setFullname] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const create = () => {
    setSubmitted(true);
    setIsChecked(true);

    if (
      fullname === "" ||
      subject === "" ||
      subject === "Sélectionner votre objet" ||
      message === "" 
      // ||
      //!isChecked
    ) {
      toast.error("Veuillez compléter tous les champs vides.");
      setIsOpen(false);
    } else {
      service
        .create({ fullname, subject, message, date })
        .then((res) => {
          toast.success("Votre avis a été envoyé avec succés.", {
            position: "top-right",
            autoClose: 20000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });

          setIsOpen(true);

          // window.location.reload(false);
        })
        .catch(() => {
          toast.error(
            "Une erreur s'est produite lors de la soumission de votre avis. Merci de réessayer de nouveau.",
            {
              position: "bottom-center",
              autoClose: 20000,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            }
          );
        });
    }
  };

  return (
    <React.Fragment>
      <div className="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-heading">
                <h2>
                  {" "}
                  Ce que <em>nos clients</em> disent de nous
                </h2>
                <span className="text-dark" style={{ textTransform: "none" }}>
                  Nos clients nous font confiance... et ils le disent !
                </span>
              </div>
            </div>
            <div className="col-md-12">
              <TestimonialsItems />
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12" align="center">
              <button
                style={{ textTransform: "none" }}
                onClick={openModal}
                className="filled-btn-avis"
              >
                Laissez votre commentaire
              </button>
            </div>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
            >
              <div className="col-12" align={"right"}>
                <button
                  style={{
                    border: "1px solid red",
                    height: 40,
                    width: 40,
                    backgroundColor: "red",
                    borderRadius: 100,
                  }}
                  onClick={closeModal}
                >
                  <i className={"fa fa-close text-white"}></i>
                </button>
              </div>
              <div className=" w-100 mt-3 ml-auto mr-auto">
                <form>
                  <h4 className="text-center">
                    Votre avis nous intéresse{" "}
                    <hr style={{ border: "0.5px solid green", width: "25%" }} />{" "}
                  </h4>
                  <div className="mb-3 mt-5 form-outline">
                    <label htmlFor="">Nom complet</label>
                  </div>
                  <input
                    className={classNames({
                      "form-control w-100 mt-3": submitted && !fullname,
                    })}
                    style={{
                      height: 60,
                      width: "100%",
                      border: "1px solid green",
                    }}
                    name="fullname"
                    onChange={(e) => setFullname(e.target.value)}
                    type="fullname"
                    placeholder=""
                    required
                  />
                  {submitted && !fullname && (
                    <small className="p-invalid">Champ Obligatoire.</small>
                  )}
                  <div className="mb-3 form-outline">
                    <label>Objet</label>
                  </div>
                  <select
                    style={{
                      height: 60,
                      width: "100%",
                      border: "1px solid green",
                    }}
                    selected="Sélectionner votre objet"
                    className={classNames({
                      "form-control w-100 mt-3": submitted && !subject,
                    })}
                    required
                    onChange={(e) => setSubject(e.target.value)}
                  >
                    <option value="Sélectionner votre objet">
                      Selectionner votre objet
                    </option>
                    <option value="Hébérgement">Hébérgement</option>
                    <option value="Activités">Activités</option>
                    <option value="Evenements">Evenements</option>
                    <option value="Qualité de Service">
                      Qualité de Service
                    </option>
                    <option value="Propreté de l'endroit">
                      Propreté de l'endroit
                    </option>
                    <option value="Repas">Repas</option>
                    <option value="Autre">Autre</option>
                  </select>
                  {/*                     <Form.Control onChange={(e) => setSubject(e.target.value)} size="lg" type="text" placeholder="" />
                   */}{" "}
                  {submitted && !subject && (
                    <small className="p-invalid">Champ Obligatoire.</small>
                  )}
                  <div className="mb-3 form-outline">
                    <label>Message</label>
                  </div>
                  <input
                    className={classNames({
                      "form-control w-100 mt-3": submitted && !message,
                    })}
                    style={{
                      height: 60,
                      width: "100%",
                      border: "1px solid green",
                    }}
                    onChange={(e) => setMessage(e.target.value)}
                    as="textarea"
                    rows={3}
                    data-error="Veuillez entrer votre message."
                    required
                  />
                  {submitted && !message && (
                    <small className="p-invalid">Champ Obligatoire.</small>
                  )}
                  <div align="center">
                    <p className="mt-4">Je ne suis pas un Robot !</p>
                    <ReCAPTCHA
                      className={classNames({
                        "alert alert-danger w-100 mt-3":
                          submitted && !isChecked,
                      })}
                      sitekey="6Ld99B8kAAAAABPs3JqZQWgSytER1RnKBEDz_xP6"
                    />
                    {submitted && !isChecked && (
                      <small className="p-invalid">
                        Veuillez confirmer que vous n'êtes pas un robot.
                      </small>
                    )}
                  </div>
                  <div align="center" className="mt-3">
                    <button
                      onClick={create}
                      className="filled-btn-avis"
                      type="submit"
                    >
                      Envoyer
                    </button>
                  </div>
                </form>
                {/* <ToastContainer
                position="bottom-center"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />*/}
                <div className="mt-5"></div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Testimonials;
