import axios from 'axios';

import DEV from "../url";


export class ConventionService {


    all() {

        return  axios.get(`${DEV}convention/all`).then((res)=>res.data);
    }
    allTn() {

        return  axios.get(`${DEV}convention/allTn`).then((res)=>res.data);
    }
    allNotTn() {

        return  axios.get(`${DEV}convention/allNotTn`).then((res)=>res.data);
    }
}
