// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricing-box {
  box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);
  padding: 35px 50px;
  border-radius: 20px;
  position: relative;
}

.pricing-box .plan {
  font-size: 34px;
}

.pricing-badge {
  position: absolute;
  top: 0;
  z-index: 999;
  right: 0;
  width: 100%;
  display: block;
  font-size: 15px;
  padding: 0;
  overflow: hidden;
  height: 100px;
}

.pricing-badge .badge {
  float: right;
  transform: rotate(45deg);
  right: -67px;
  top: 17px;
  position: relative;
  text-align: center;
  width: 200px;
  font-size: 13px;
  margin: 0;
  padding: 7px 10px;
  font-weight: 500;
  color: #ffffff;
  background: #fb7179;
}
.mb-2, .my-2 {
    margin-bottom: .5rem!important;
}
p {
    line-height: 1.7;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/packs.css"],"names":[],"mappings":"AAAA;EAEU,iDAAiD;EACzD,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,YAAY;EACZ,QAAQ;EACR,WAAW;EACX,cAAc;EACd,eAAe;EACf,UAAU;EACV,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,YAAY;EAEJ,wBAAwB;EAChC,YAAY;EACZ,SAAS;EACT,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,SAAS;EACT,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,mBAAmB;AACrB;AACA;IACI,8BAA8B;AAClC;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".pricing-box {\n  -webkit-box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);\n          box-shadow: 0px 5px 30px -10px rgba(0, 0, 0, 0.1);\n  padding: 35px 50px;\n  border-radius: 20px;\n  position: relative;\n}\n\n.pricing-box .plan {\n  font-size: 34px;\n}\n\n.pricing-badge {\n  position: absolute;\n  top: 0;\n  z-index: 999;\n  right: 0;\n  width: 100%;\n  display: block;\n  font-size: 15px;\n  padding: 0;\n  overflow: hidden;\n  height: 100px;\n}\n\n.pricing-badge .badge {\n  float: right;\n  -webkit-transform: rotate(45deg);\n          transform: rotate(45deg);\n  right: -67px;\n  top: 17px;\n  position: relative;\n  text-align: center;\n  width: 200px;\n  font-size: 13px;\n  margin: 0;\n  padding: 7px 10px;\n  font-weight: 500;\n  color: #ffffff;\n  background: #fb7179;\n}\n.mb-2, .my-2 {\n    margin-bottom: .5rem!important;\n}\np {\n    line-height: 1.7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
