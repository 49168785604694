import axios from "axios";
import DEV from "../url";
export class ActivitéService {

    getAll(){
        return axios.get(`${DEV}activity/all`).then((data)=>data.data.result);
    }

    getAllLimit(){
        return axios.get(`${DEV}activity/limit`).then((data)=>data.data.result);
    }

    getRecent()
    {
        return axios.get( `${DEV}activity/recent`);
    }

    getSingleData(id)
    {
        return axios.get(`${DEV}activity/get/${id}`).then((res)=>res.data);
    }

    getActivityImages(id)
    {
        return axios.get(`${DEV}activity/images/${id}`);
    }
}
