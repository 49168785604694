import React, { useEffect, useState } from "react";
import { ProductService } from "../../../../../services/ProductService";
import { useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ProductHeading from "../ProductHeading";
import DEV from "../../../../../url";
function ProductDetails() {
  const service = new ProductService();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [img, setImg] = useState("");

  const [images, setImages] = useState([null]);

  const fetch = () => {
    service.get(id).then((res) => {
      setTitle(res.title);
      setDescription(res.description);
      setType(res.type);
      setImg(res.img);
    });
  };

  const fetch_images = () => {
    service.getImages(id).then((res) => {
      setImages(res);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    fetch_images();
  }, []);

  return (
    <React.Fragment>
      <ProductHeading />
      <div className="container mt-5 pt-5" id={"terroir"}>
        <div className="row mt-5 pt-5">
          <div className="col-md-6">
            <div className="project-info-box mt-0">
              <h2 className="text-mobile-center">
                {title ? (
                  <React.Fragment>Détails : {title}</React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </h2>
              <p className="mb-0 mt-1 alert alert-warning mt-3">
                {description ? (
                  <React.Fragment>
                    <i className={"fa fa-edit"}></i>
                    {description}
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </p>
              <p className="mt-3 text-mobile-center">
                <b>Type de ce produit : </b>
                {type ? (
                  <b className={"text-success"}>{type}</b>
                ) : (
                  <span></span>
                )}
              </p>
            </div>
            <div className="row mt-2 mx-3"></div>
          </div>

          <div className="col-md-6">
            <div className="container mt-2">
              {
                images.length === 0 ?
                <h1 className="text-center alert alert-warning">Ce produit ne contient pas d'autres images.</h1> : 
                <Carousel
                useKeyboardArrows={true}
                infiniteLoop={true}
                emulateTouch={true}
                dynamicHeight={true}
                showArrows={false}
              >
               
                  <img
                    src={`../uploads/products/${img}`}
                    alt="product-img"
                    className="img-product-details"
                  />
             
             <React.Fragment>
                    {images.map((item, index) => (
                      <div className="container" key={index}>
                        <img
                          src={`${DEV}uploads/products/${item?.img}`}
                          alt="product-img"
                        />
                      </div>
                    ))}
                  </React.Fragment>
                
              </Carousel>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5"></div>
    </React.Fragment>
  );
}

export default ProductDetails;
