import axios from "axios";
import DEV from "../url";


export class BookingService {

    bookARoom(data){
       return axios.post( `${DEV}book/book`,data).then((res)=>res.data);
    }
    getAll(){
       return axios.get( `${DEV}book/all`).then((res)=>res.data);
    }

    supprimer(id)
    {
        return axios.delete(`${DEV}book/delete/`+id);
    }
    getBook(id)
    {
        return axios.delete(`${DEV}book/get/`+id);
    }
}
