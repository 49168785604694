import axios from 'axios';
import DEV from "../url";

export class LiensUtilesService{

    async all()
    {
        const res = await axios.get(`${DEV}links/all`);
        return res?.data;
    }
}
