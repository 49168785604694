import React,{useState,useEffect} from 'react';
import {ChannelService} from "../../../../services/ChannelService";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import DEV from '../../../../url';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};



function VuRéseauxSociaux() {

    const [items_,setItems_]=useState([]);


    const _ = new ChannelService();


  const allradios = () => {
        _.allradios().then((res)=>{
                setItems_(res);
        });
    }

    useEffect(()=>{
        allradios();
    },[]);

    return (
        <React.Fragment>
            <div align="center" className='more-info-content'>
                <h1> <b> Radios </b></h1>
            </div>
            <div className="row">
        <div className="col-12" align="center">
          <Carousel
            arrows={true}
            align={"center"}
            className={"w-100 mt-5"}
            draggable={true}
            responsive={responsive}
          >
            {items_.map((item, index) => (
              <div key={index} className="col-lg-2 col-md-6 col-sm-12">
                <a target={"_blank"} className={"text-center"} href={item.link}>
                  <img
                    alt=""
                    width={150}
                    height={120}
                    src={`${DEV}uploads/channels/${item?.logo}`}
                  />
                </a>
              </div>
            ))}
          </Carousel>
        </div>
      </div>



        </React.Fragment>
    );
}

export default VuRéseauxSociaux;
