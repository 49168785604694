import React, { useEffect, useState } from "react";
import { EventService } from "../../../../services/EventService";
import {ContactInformation} from "../../../../services/ContactInformation";
import IconEvent from "../../../../assets/images/iconEvent.png";
import HeadingEvent from "./HeadingEvent";
import DEV from '../../../../url';

function Events() {

    const [items, setItems] = useState([]);
    const [searchitem, setSearchitem]= useState('');
    const service = new EventService();
    const all = async () => {
        service.getAll().then((res) => {
            setItems(res);
        });
    }

    useEffect(() => {
        all();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(9);
    const [pageNumberLimit] = useState(10);
    const [maxpageNumberLimit, setMaxPageNumberLimit] = useState(10);
    const [minpageNumberLimit, setMinPageNumberLimit] = useState(0);


    const handleClick = (event) => {
        setCurrentPage(Number(event.target.id));
    }
    const pages = [];
    for (let i = 1; i <= Math.ceil(items.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map(number => {
        if (number < maxpageNumberLimit + 1 && number > minpageNumberLimit) {
            return (
                <li className="page-item" key={number} id={number} ><button onClick={handleClick} className="page-link">{number}</button></li>

            );
        } else {
            return null;
        }
    });

    const handleNextbtn = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxpageNumberLimit) {
            setMaxPageNumberLimit(maxpageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minpageNumberLimit + pageNumberLimit);
        }
    }
    const handlePrevbtn = () => {
        setCurrentPage(currentPage - 1)
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setMaxPageNumberLimit(maxpageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minpageNumberLimit - pageNumberLimit);
        }
    }

    const contact = new ContactInformation();
    const[rows,setRows]=useState([]);

     const fetchall = async () => {
        contact.all().then((res) => {
            setRows(res);
        });
    }
    useEffect(() => {
        fetchall();
    }, []);

    return (

        <React.Fragment>
           <HeadingEvent/>
            <div className="breadcrumb-section mt-5" id={"events"}>

                <div className="events_area spad pt-5">
                    <div className="container pt-5">
                        <div className={"pt-5"}>


                            <dl>
                                <dt>Pour vos évenements</dt>
                                <dd> - Réceptions, séminaires, réunions, mariages, anniversaires,
                                    fêtes de fin
                                    d’année scolaire, fêtes d’entreprise, team building,…
                                </dd>
                                <dt>Dar Zaghouan vous propose</dt>
                                <dd> - Une salle en bois écologique toute équipée, d’une capacité pouvant accueillir
                                    de 50 à
                                    150 convives.
                                </dd>

                                <dd>- Une salle en verrière équipée avec une jolie vue sur le jardin, pouvant
                                    accueillir de
                                    10 à 30 convives.
                                </dd>
                                <dd> -Une grande pelouse, face à la superbe montagne de Zaghouan. La piscine et ses
                                    alentours pouvant accueillir jusqu'à
                                    800 convives.
                                </dd>
                            </dl>

                        </div>

                        <div className="row mt-5">
                            <div className="col-md-4">
                                {/*                     <input style={{ height: 50 }} type="text" name="globalFilter" onChange={(e) => setGlobalFilter(e.target.value)} className="form-control" placeholder="Cherchez une activité ..." autoComplete="on" />
 */}

                                <select className="form-control" 
                                style={{ height: 50, borderTopRightRadius: 20, borderBottomLeftRadius: 20 }} 
                                name="searchitem"
                                onChange={(e)=>setSearchitem(e.target.value)}>
                                    <option disabled value="0">Sélectionner votre type d'événement</option>
                                    <option value="" onClick={all}>Tous les types d'événements</option>
                                    <option value="Mariage & Réception">Mariage & Réception</option>
                                    <option value="Réunion & Séminaire">Réunion & Séminaire</option>
                                    <option value="Team Building">Team Building</option>
                                    <option value="Fêtes de fin année scolaire">Fêtes de fin année scolaire</option>
                                    <option value="Anniversaires">Anniversaires</option>


                                </select>
                            </div>
                        </div>

                        <div className="row mt-3 last">



                            {
                                items
                                .filter(item_=>{
                                    if(searchitem === "")
                                     {return item_;}
                                   else if (item_.type.toLowerCase().includes(searchitem.toLowerCase()))
                                     {
                                       return item_;
                                     }
                                 })
                                .map((item, index) => (
                                    <div key={index} className="col-lg-4 col-md-4">
                                        <div className="card-body">

                                            <div  className="card-wrapper mt-4">
                                        <div className="card-img">
                                                <img src={`${DEV}uploads/events/${item?.img}`} alt="" height={370}/>
                                        </div>
                                        <div className="card-body">
                                            <div>
                                                <img width={140} src={IconEvent} alt={item.title} />
                                            </div>
                                        </div>
                                    </div>
                                            <div className="row w-100">
                                                <div className="col-12 mt-3" align={"center"}>
                                                    <div className="row">
                                                        <div  className="col-lg-6">
                                                             <h6 style={{fontSize:17}} className="text-secondary mb-2">
                                                                 {
                                                                     item.title
                                                                 }
                                                </h6>
                                                        </div>
                                                        <div align={""} className="col-lg-6">
                                                            <p>{item.price == 0 ? <></> : <p><b className={"text-success"}>A partir de {item.price} {"DT"}</b></p> }</p>
                                                        </div>
                                                    </div>
                                                    <div className="card col-12 mt-4">
                                                        <div className="card-body">
                                                            <h6 className={"text-success"}><b>{item.type}</b></h6>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                              <div align={"center"} className={"mt-4"}>
                                                    <a className={"filled-btn-avis"} href={`evenement-details-${item.id}`}>Voir détails</a>
                                               </div>
                                        </div>

                                    </div>
                                ))
                            }



                        </div>






                    </div>

                </div>
            </div>


            <br /><br /><br /><br />
        </React.Fragment>
    );
}

export default Events;
