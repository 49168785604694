import React from 'react';
import ProductHeading from './ProductHeading';
import ProductItems from './ProductItems';

function Products() {
    return (
        <React.Fragment>
                <ProductHeading/>
                <ProductItems/>
        </React.Fragment>
    );
}

export default Products;