// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.project-info-box {
    background-color: #fff;
    padding: 30px 20px;
    border-radius: 5px;
  }
  
  .project-info-box p {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #d5dadb;
  }
  
  .project-info-box p:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .img-room {
      width: 100%;
      max-width: 100%;
      height: auto;
  }
  .rounded {
      border-radius: 5px !important;
  }
`, "",{"version":3,"sources":["webpack://./src/assets/css/RoomDetails.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;IACnB,oBAAoB;IACpB,gCAAgC;EAClC;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;EACrB;EACA;MACI,WAAW;MACX,eAAe;MACf,YAAY;EAChB;EACA;MACI,6BAA6B;EACjC","sourcesContent":["\n.project-info-box {\n    background-color: #fff;\n    padding: 30px 20px;\n    border-radius: 5px;\n  }\n  \n  .project-info-box p {\n    margin-bottom: 15px;\n    padding-bottom: 15px;\n    border-bottom: 1px solid #d5dadb;\n  }\n  \n  .project-info-box p:last-child {\n    margin-bottom: 0;\n    padding-bottom: 0;\n    border-bottom: none;\n  }\n  .img-room {\n      width: 100%;\n      max-width: 100%;\n      height: auto;\n  }\n  .rounded {\n      border-radius: 5px !important;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
