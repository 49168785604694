import axios from 'axios';
import DEV from "../url";


export class BannerService {

    getBanners()
    {
        return axios.get(`${DEV}banner/all`).then((data)=>data.data);
    }
}
