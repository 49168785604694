import React, {useEffect, useState} from "react";
import {ContactInformation} from "../../../../services/ContactInformation";
import {HeadingService} from "../../../../services/HeadingService";
import Scroll from "react-scroll-to-element";
import DEV from "../../../../url";
export default function HeadingActivities() {

   const heading = new HeadingService();

   const [items, setItems] = useState([null]);

   const fetchHeading = () => {

       heading.allAct().then((res)=>{
           setItems(res.data);
       })
   }

   useEffect(()=>{
        fetchHeading();
   },[]);


    const contact = new ContactInformation();
    const [rows, setRows] = useState([]);

    const fetchall = async () => {
        contact.all().then((res) => {
            setRows(res);
        });
    }
    useEffect(() => {
        fetchall();
    }, []);

    return (
       <React.Fragment>
           {
               items.map((row,index)=>(
            <div key={index} style={{backgroundImage:`url(${DEV}uploads/headings/${row?.img})`}}  className="page-heading header-text">
                {
                    row?.type_heading === 'Activite' &&
                        <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 style={{fontSize:'6vh',textTransform:'none'}}>Découvrez nos activités</h1>
                        <p style={{fontSize:16.5,color:"white",wordSpacing:8,lineHeight:1.5}}>Dar Zaghouan vous offre une gamme d'activités de plusieurs types : <br/> Intérieures, Extérieures, Culturelles & Ateliers </p>
                        <div align={"center"} className={"mt-3"}>
                                          {
                                           rows.map((item_,index_)=>(
                                               <React.Fragment key={index_}>
                                                   {
                                                       item_.title ===  "Renseignement" ?
                                                           <a href={`tel:${item_.phonenumber}`} className="filled-btn-avis"> <i className="fa fa-phone mx-2"/>  {item_.phonenumber}</a>
                                                                : <></>
                                                   }
                                               </React.Fragment>
                                           ))
                                       }
                              </div>
                    </div>
                </div>
                               <Scroll style={{bottom: 0}} type="id" element="activities">
                                        <button  style={{marginTop:0}} className={"filled-btn-scroll mt-5"}>
                                            <i className={"fa fa-arrow-down"}></i>
                                        </button>
                                </Scroll>
            </div>
                }

        </div>
                     ))
           }

        </React.Fragment>
    )
}
