import React, { useEffect, useState } from "react";
import { LiensUtilesService } from "../../../../services/LiensUtilesService";

const Article = () => {
  const ls = new LiensUtilesService();
  const [items, setItems] = useState([]);
  const fetchAll = () => {
    ls.all().then((res) => {
      setItems(res);
    });
  };
  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <React.Fragment>
      <div align="center" className="more-info-content">
        {items.map((item, index) => (
          <div key={index} style={{ textAlign: "center" }}>
            {item.title.startsWith("Nos") && (
              <a
                style={{ textAlign: "center" }}
                className={"filled-btn-avis text-center"}
                href={item.link}
              >
                <h1 style={{ textAlign: "center" }}>
                  <i className="fa fa-file"></i> Articles de presse{" "}
                </h1>{" "}
              </a>
            )}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Article;
