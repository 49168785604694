import axios from 'axios';
import DEV from "../url";

export class ProductService{

    async all(){
        const res = await axios.get(`${DEV}product/all`);
        return res.data;
    }

    async get(id){
        const res = await axios.get(`${DEV}product/get/${id}`);
        return res.data;
    }

    async getImages(id){
        const res = await axios.get(`${DEV}product/images/` + id);
        return res.data;  
    }
}
