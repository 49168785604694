/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RoomsService } from "../../../../../services/RoomsService";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import RoomsHeading from "../RoomsHeading";
import DEV from "../../../../../url";

function RoomDetails() {
  const { id } = useParams();
  const service = new RoomsService();
  const [images, setImages] = useState([null]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");
  const [type, setType] = useState("");
  const [options, setOptions] = useState("");
  const [rules, setRules] = useState("");
  const [maxCapacity, setMaxCapacity] = useState(0);

  const getdetails = () => {
    service.get(id).then((res) => {
      setTitle(res.title);
      setDescription(res.description);
      setImg(res.img);
      setType(res.type);
      setOptions(res.options);
      setRules(res.rules);
      setMaxCapacity(res.maxCapacity);
    });
  };

  const fetch_images = () => {
    service.getImages(id).then((res) => {
      setImages(res);
    });
  };

  useEffect(() => {
    fetch_images();
  }, []);

  useEffect(() => {
    getdetails();
  }, []);

  return (
    <React.Fragment>
      <RoomsHeading />
      <div className="container mt-5 pt-5" id="rooms">
        <div className="row mt-5">
          <div className="col-md-4">
            <div className="project-info-box mt-0">
              <h1>
                {title ? (
                  <React.Fragment><h1 className="text-mobile-center">{title}</h1></React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </h1>

              <p className="mb-0 mt-3 text-mobile-center">
                {description ? (
                  <React.Fragment>{description}</React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </p>
              <p className="mb-0 mt-1 text-mobile-center">
                {options ? (
                  <React.Fragment> <b>Options</b> : {options}</React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </p>
            
              <p className="mt-3 text-mobile-center">
                <b>Type : </b>
                {type ? <span>{type}</span> : <span></span>}
              </p>
              <p className="mt-3 text-mobile-center">
                <b>Capacité Moyenne : </b>
                {maxCapacity ? (
                  <span>
                    {maxCapacity == 1 ? (
                      <span>1 personne</span>
                    ) : (
                      <span>{maxCapacity} personnes</span>
                    )}
                  </span>
                ) : (
                  <span></span>
                )}
              </p>

              <p></p>
            </div>
            <a
              style={{ textTransform: "none" }}
              href={`/reserver`}
              className="filled-btn-avis mx-2 reserver-mobile-center"
            >
              Réserver votre chambre
            </a>
            <div className="mt-5"></div>
          </div>
          <div className="col-3"></div>
          <div className="col-md-5">
            <Carousel
              className={"mt-4"}
              useKeyboardArrows={true}
              infiniteLoop={true}
              emulateTouch={true}
              dynamicHeight={true}
              showArrows={false}
            >
              {img ? (
                <img
                  src={`${DEV}uploads/rooms/${img}`}
                  alt="room-image"
                  className="img-room"
                />
              ) : (
                <span></span>
              )}

              {images.map((item, index) => (
                <div className="container" key={index}>
                  <img
                    src={`${DEV}uploads/rooms/${item?.img}`}
                    alt="room-img"
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      <div className="mt-5"></div>
    </React.Fragment>
  );
}

export default RoomDetails;
