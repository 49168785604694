import axios from 'axios';
import DEV from "../url";

export class ContactInformation {

    async all(){
        const res = await axios.get(`${DEV}contactInfo/all`);
        return res?.data;
       
    }

     async emailAndRenseignement(){
        const res =  await axios.get(`${DEV}contactInfo/emailAndRenseignement`);
        return res?.data;
       
    }
}
