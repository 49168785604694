import axios from "axios";
import DEV from "../url";

export class ValeurService {


    async getAll ()
    {
        const res = await axios.get(`${DEV}valeur/all`);
        return res.data;
    }
}