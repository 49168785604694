import React, { useEffect, useState } from 'react';
import { RoomsService } from '../../../../services/RoomsService';
import IconRoom from "../../../../assets/images/iconRoom.png";
import ReactReadMoreReadLess from "react-read-more-read-less";
import DEV from '../../../../url';

function RoomItems() {
    const service = new RoomsService();



    const [items, setItems] = useState([]);
    const [searchitem, setSearchitem] = useState('');

    const all = async () => {
        service.all().then((res) => {
            setItems(res);
        });
    }

    

    useEffect(() => {
        all();
    }, []);


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(9);
    const [pageNumberLimit] = useState(10);
    const [maxpageNumberLimit, setMaxPageNumberLimit] = useState(10);
    const [minpageNumberLimit, setMinPageNumberLimit] = useState(0);


    const handleClick = (event) => {
        setCurrentPage(Number(event.target.id));
    }
    const pages = [];
    for (let i = 1; i <= Math.ceil(items.length / itemsPerPage); i++) {
        pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
    const renderPageNumbers = pages.map(number => {
        if (number < maxpageNumberLimit + 1 && number > minpageNumberLimit) {
            return (
                <li className="page-item" key={number} id={number} ><button onClick={handleClick} className="page-link">{number}</button></li>

            );
        } else {
            return null;
        }
    });

    const handleNextbtn = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > maxpageNumberLimit) {
            setMaxPageNumberLimit(maxpageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minpageNumberLimit + pageNumberLimit);
        }
    }
    const handlePrevbtn = () => {
        setCurrentPage(currentPage - 1)
        if ((currentPage - 1) % pageNumberLimit == 0) {
            setMaxPageNumberLimit(maxpageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minpageNumberLimit - pageNumberLimit);
        }
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    {/*                     <input style={{ height: 50 }} type="text" name="globalFilter" onChange={(e) => setGlobalFilter(e.target.value)} className="form-control" placeholder="Cherchez une activité ..." autoComplete="on" />
 */}
                    <select
                        
                        className="form-control"
                        style={{ height: 50, borderTopRightRadius: 20, borderBottomLeftRadius: 20 }}
                        name="searchitem" onChange={(e)=>setSearchitem(e.target.value)}>
                        <option disabled value="0">Sélectionner votre type de chambre</option>
                        <option value="">Tous les types de chambres</option>
                        <option value="Chalet">Chalet</option>
                        <option value="Chambre Hôte">Chambre Hôte</option>
                        <option value="Gîte">Gîte</option>
                        <option value="Suite">Suite</option>
                    </select>
                </div>
            </div>
            <div className="row last mt-3">
                {
                    items
                    .filter(item_ => {
                        if (searchitem === "") { return item_; }
                        else if (item_.type.toLowerCase().includes(searchitem.toLowerCase())) {
                            return item_;
                        }
                    })
                    .map((item, index) => (
                        <div key={index} className="col-md-4 ">
                            <div className="service-item ">
                                <div className="card-wrapper">
                                    <div className="card-img">
                                        <img height={250} src={`${DEV}uploads/rooms/${item?.img}`} alt="" />
                                    </div>
                                    <div className="card-body">
                                        <div>
                                            <img  height={100} src={IconRoom} alt={item.title} />

                                        </div>
                                    </div>

                                </div>

                                <div className="down-content">
                                    <div className="row">
                                              <div className='col-12' align="center">
                                              <div className="col-lg-5">
                                                    <div className="title-product mt-3">
                                                <h6 style={{fontSize:17}} className=" mb-2 text-secondary">{item.title}
                                                </h6>
                                            </div>
                                              </div>
                                              <div className="col-lg-7">
                                                  <div className={"mt-3"}>{item.price == 0 ? <></>  : <p><b className={"text-success"}>{"A partir de "} {item.price} {"DT / Personne / Nuit"}</b></p> }</div>
                                              </div>
                                              </div>
                                          </div>
                                    <div style={{ marginBottom: "10px" }} className={"mt-3"}>
                                        <span>Capacité Moyenne : {item.maxCapacity} </span>
                                    </div>
                                    <p> <i className="fa fa-edit"></i> &nbsp;
                                            {
                                                    item.description ==  null ? <small className={"alert alert-warning"}> Contenu indisponible pour le moment</small>
                                                    : <ReactReadMoreReadLess readMoreClassName={"text-success "} readLessClassName={"text-dark"}  charLimit={100} readMoreText={"Voir plus ▼"} readLessText={"Voir moins ▲"}>
                                                            {item.description}
                                                        </ReactReadMoreReadLess>
                                            }
                                    </p>

                                    <div style={{bottom:0}} align="center">
                                        <a style={{textTransform:'none'}}  href={`/room-${item.id}`} className="filled-btn-avis">Voir détails</a>
                                    </div>
                                </div>
                            </div>


                            <br />
                        </div>
                    ))
                }


            </div>

            <br />
            <br />



            <br />
            <br />
            <br />
            <br />
        </div>
    );
}

export default RoomItems;
