import React, {useEffect, useState} from 'react';
import {SpaService} from "../../../../services/SpaService";
import {Carousel} from 'react-responsive-carousel';
import DEV from '../../../../url';

const SpaItems = (props) => {


    const service = new SpaService();
    const [items, setItems] = useState([]);



    const [rows, setRows] = useState([]);

    const all = async () => {
        service.all().then((res) => {
            setItems(res);
        });
    }

    const allHammamSpa = async () => {
        service.allHammamSpa().then((res) => {
            setRows(res);
        });
    }

    useEffect(() => {
        all();
    }, []);


    useEffect(() => {
        allHammamSpa();
    }, []);






 

    return (
        <React.Fragment>

            <div className="row pt-5">
                {
                    items.map((item, index) => (
                        <div className={"row pt-5 mt-3 last"} key={index}>
                             <h1 className={"text-center col-12"}><b>{item.title}</b>
                                    <hr
                                        style={{width: 200, backgroundColor: "green"}}/>
                                </h1>
                            <div className="col-lg-6 col-sm-12 pt-5" >

                                    <h6 style={{lineHeight: 2, wordSpacing: 3}}
                                        className={"text-center col-md-5 mt-4"}>
                                        {item.description}
                                    </h6>
                            </div>

                            <div className="col-lg-4 col-sm-12  pt-1" style={{width:200}}>
                                <Carousel useKeyboardArrows={true} infiniteLoop={true} emulateTouch={true}
                                          dynamicHeight={true} showArrows={false}>
                                    {rows?.map((row, indexRow) => (

                                        <img key={indexRow} className={"mt-2"}
                                             src={`${DEV}uploads/hammams/${row?.img}`}
                                             alt=""/>
                                    ))}
                                </Carousel>
                            </div>


                        </div>
                    ))
                }
            </div>

            <div className="mt-5"></div>
        </React.Fragment>
    )
}

export default SpaItems;
