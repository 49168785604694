import axios from "axios";
import DEV from "../url";

export class WhoareweService {

    getContentSectionsToMainPage()
    {
        return axios.get(`${DEV}whoarewe/displaytomainpage`);
    }

    getContentSectionsNotToMainPage()
    {
        return axios.get(`${DEV}whoarewe/displaynottomainpage`);
    }
}
