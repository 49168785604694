import React, {useState,useEffect} from 'react';
import {ChannelService} from "../../../../services/ChannelService";
import {useParams} from "react-router-dom";
import Moment from "react-moment";

const VidéoChaine = () => {

    const { id } = useParams();
    const[items,setItems]=useState([]);
    const[rows,setRows]=useState([]);

    const service = new ChannelService();

    const fetch = () => {
        service.allV(id).then((res)=>{
            setItems(res);
        })
    }

    useEffect(() => {
       fetch();
    }, []);

    const fetch_ = () => {
        service.all().then((res)=>{
            setRows(res);
        })
    }

    useEffect(() => {
       fetch_();
    }, []);



    return(
        <React.Fragment>
            <div className="page-heading header-text">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Vidéo </h1>
                            <span>Dar Zaghouan aux Yeux de la Télé </span>
                        </div>
                    </div>
                </div>
        </div>
            <div className="container mt-5">

                {
                    items.length === 0 ?
                        <div className="row">
                            <div align={"center"} className="col-md-12">
                                <h3>Aucune Vidéo n'a été trouvée </h3>
                            </div>
                        </div>
                         :

                        <div  className="row last">
                            <div className="col-12" align={"center"}>
                                <h2>Consulter ce passage</h2>
                            </div>
                    {
                        items.map((item,index)=>(
                           <React.Fragment key={index}>
                                <div className="row mt-4">
                                    <div align={''} className="col-md-12">
                                            <iframe style={{height: 250, width: 400}} src={item.link}
                                                    frameBorder="0"></iframe>
                                          <div className="row">
                                              <div className="col-12">
                                                  <button disabled className={"filled-button w-100"}>
                                             Date  : <Moment format={"YYYY MMM dd"}>
                                        {item.datePost}
                                    </Moment>
                                        </button>
                                              </div>
                                          </div>
                                    </div>
                                    <div className="col-md-5" align={"right"}>


                                    </div>
                                </div>
                           </React.Fragment>
                        ))
                    }
                </div>
                 }

            </div>
            <div className="mt-5"></div>

        </React.Fragment>
    )

}

export default VidéoChaine;
