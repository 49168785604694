import React, { useEffect, useState } from "react";
import { LabellisationService } from "../../../../services/LabellisationService";


export default function Labellisation() {

    const service_ = new LabellisationService();

    const [items_, setItems_] = useState([null]);

    useEffect(() => {
        service_.getAll().then((res) => {
            setItems_(res.data);
        });
    }, []);


    return (
        <div className="col-md-12">
            <div className="more-info-content">
                <div className="row">
                    {
                        items_.map((item, index) => (
                            <div key={index} className="col-md-12 align-self-center">
                                <div className="right-content" align="center">
                                    <span style={{textTransform:'none'}}>{item?.subtitle}</span>
                                    <h1><em>{item?.title} </em> </h1>
                                    <p className="text-center">
                                        {item?.text}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )

}
