import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ActivitéService } from "../../../../../services/ActivitéService";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import HeadingActivities from "../HeadingActivities";
import DEV from "../../../../../url";
function ActivityDetails() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [img, setImg] = useState("");
  const [type, setType] = useState("");
  const [dateAc, setDateAc] = useState("");
  const [imgs_, setImgs_] = useState([null]);
  const service = new ActivitéService();

  const get = () => {
    service.getSingleData(id).then((res) => {
      setTitle(res.title);
      setDescription(res.description);
      setImg(res.img);
      setType(res.type);
      setDateAc(res.dateAc);
    });
  };

  const getImages = () => {
    service.getActivityImages(id).then((res) => {
      setImgs_(res.data);
    });
  };

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    getImages();
  }, []);

  return (
    <React.Fragment>
      <HeadingActivities />
      <div className="container mt-4" id="activities">
        <div className="row mt-5 pt-5">
          <div className="col-lg-4 col-sm-12 mt-5">
            <h2 className={"mt-3 text-mobile-center"}> Détails : {title}</h2>
            <br />
            <span className="reserver-mobile-center">  Type de cette activité : <b className={"text-success"}>{type}</b></span>
            <div className="alert alert-warning mt-3">
              <p>
                {description === null ? (
                  <p>
                    Pas de contenu disponible pour le moment.{" "}
                    <i className={"fa fa-spinner"}></i>{" "}
                  </p>
                ) : (
                  <p>Description : {description}</p>
                )}
              </p>
            </div>
          </div>
          <div className="col-md-6 last mt-5">
            <br />
            <Carousel
              useKeyboardArrows={true}
              infiniteLoop={true}
              emulateTouch={true}
              dynamicHeight={true}
              showArrows={false}
            >
              {img ? (
                <img
                  className=""
                  src={`${DEV}uploads/activities/${img}`}
                  alt={title}
                />
              ) : (
                <></>
              )}
              {imgs_.map((item, index) => (
                <div key={index} className="container">
                  <img
                    src={`${DEV}uploads/activities/${item?.img}`}
                    alt="détail-activité-img"
                  />
                </div>
              ))}
            </Carousel>
          </div>

          {/*<div className="col-md-6">
            <h4 className="h4">{title ? <span>{title}{" "}<Badge type={"danger"} style={{ color: "white" }}>{type}</Badge></span> : <></>}</h4>
            <p className="text-justify mt-4">{description ? <span>{description}</span> : <></>}</p>




            <div className="mt-4">
              <div className="row">
                <div className="col-12">Note</div>
                <div className="col-5">
                  <i style={{ color: "#03AC13" }} className="fa fa-star" />
                  <i style={{ color: "#03AC13" }} className="fa fa-star" />
                  <i style={{ color: "#03AC13" }} className="fa fa-star" />
                  <i style={{ color: "#03AC13" }} className="fa fa-star" />
                  <i style={{ color: "#FFE2E5" }} className="fa fa-star" />
                </div>
              </div>
            </div>


          </div>*/}
        </div>
      </div>

      <div className="mt-5"></div>
    </React.Fragment>
  );
}

export default ActivityDetails;
