import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { WhoareweService } from "../../../services/WhoareweService";
import { fadeInLeft, fadeInRight } from "react-animations";
import Radium, { StyleRoot } from "radium";
import DEV from "../../../url";


const styles = {
    fadeInLeft: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeInLeft, 'fadeInLeft')
    },
    fadeInRight: {
        animation: 'x 1s',
        animationName: Radium.keyframes(fadeInRight, 'fadeInRight')
    }
}
function About() {

    const [items, setItems] = useState([null]);
    const service = new WhoareweService();

    useEffect(() => {
        service.getContentSectionsToMainPage().then((res) => {
            setItems(res.data);
        });
    }, []);

    return (
        <React.Fragment>

            <div className="fun-facts">
                <div className="container">
                    <div className="more-info-content">
                    <StyleRoot>

                        <div className="row">

                                {
                                    items.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <div className="col-md-6">
                                                <div className="left-image">
                                                    <img style={{borderTopLeftRadius:25, borderBottomRightRadius:25}} src={`${DEV}uploads/whoarewe/${item?.img}`} className="img-fluid" alt={item?.sectionName} />
                                                </div>
                                            </div>
                                            <div className="col-md-6 align-self-center">
                                                <div className="right-content " style={styles.fadeInRight}>
                                                    <p style={{fontSize:17}}>{item?.tag}</p>
                                                    <h2 style={{textTransform:"none"}}>{item?.sectionName}</h2>
                                                    <p align="justify">
                                                        {item?.text}
                                                    </p>
                                                    <div className="last">
                                                    <Link to="/about" className="filled-btn-avis">Voir plus</Link>

                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))
                                }

                        </div>
                        </StyleRoot>

                    </div>
                </div>
            </div>



        </React.Fragment>

    );
}
export default About;
