import React from "react";

import Items from "./Sliders/Items";
import RequestCallBack from "./RequestCallBack";

function MainBanner() {

    return (
        <React.Fragment>
            <div className="main-banner header-text" id="top">
                <div className="Modern-Slider">

                    <Items/>


                </div>

            </div>
            <RequestCallBack/>

        </React.Fragment>
    );
}

export default MainBanner;
