import React, {useEffect, useState} from "react";

import tisanerieIcon from "../../../../../assets/images/tisanerieIcon.png";

import {RestoService} from "../../../../../services/RestoService";
import DEV from "../../../../../url";

function Tisanerie() {

    const service = new RestoService();

    const [items, setItems] = useState([]);

    const fetch = () => {
        service.allTisanerie().then((res) => {
            setItems(res);
        });
    }


    useEffect(() => {
        fetch();
    }, []);

    return (

        <React.Fragment>
            {
                items.length != 0 ?

        <div className="container mt-5">

                    <>
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title">
                                <h2 className="text-center">
                                    Tisanerie
                                    <hr style={{width: 200, backgroundColor: 'green'}}/>
                                </h2>
                            </div>
                        </div>
                    </div>
            <div className="row last" >
                {
                    items.map((item, index) => (
                        <React.Fragment key={index}>
                            {
                                item.type === 'Tisanerie' ?
                                    <div key={index} className="col-sm-6 col-lg-4 mb-2-6 mb-lg-0 ">
                                        <div className="card-wrapper mb-4">
                                            <div className="card-img"><img width={350} height="300" src={`${DEV}uploads/restaurants/${item?.img}`} alt="..." />
                                            </div>
                                            <div className="card-body">
                                                <div>
                                                    <img width={140} src={tisanerieIcon} alt={item.title}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                           <div className="row">
                                               <div className="col-lg-3">
                                                    <p style={{fontSize:17}} className=" mb-2"><a href="#" className="text-secondary">  <b>{item.title}</b></a></p>
                                               </div>
                                               <div className="col-lg-1"></div>
                                               <div className="col-lg-8" align={"right"}>
                                               {
                                                       item.price === 0 ?
                                                           <></> :
                                                           <React.Fragment>
                                                             <small className={"text-success"}><b>A partir de</b> </small> <p className={"text-success"}><b>{item.price}{" DT / Personne "}</b></p>
                                                           </React.Fragment>
                                                   }

                                               </div>
                                           </div>
                                            <div className="product-rating mb-2 mt-4">
                                                <div className="row">
                                                    <div className="col-md-12 col-sm-12">
                                                        <a href={`/tisanerie-details${item.id}`} className="filled-btn-avis mt-2">
                                                                Voir Détails
                                                        </a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : <></>
                            }


                        </React.Fragment>
                    ))
                }


            </div>

                    </>
        </div>
                    : <></> }
        </React.Fragment>

            );
}
export default Tisanerie;
