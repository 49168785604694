import React from 'react';
function RequestCallBack(){
    return (
<React.Fragment>
    <div className="request-form" style={{height:"auto"}}>
            <div className="container">
                <div className="row" style={{marginTop:-15}}>
                    <div className="col-md-8 col-sm-12">
                        <h4 className=''>Avez-vous besoin de plus d'informations ?</h4>
                        <span></span>
                    </div>
                    <div style={{marginTop:-20}} className="col-md-4 col-sm-12">
                        <a style={{textTransform:'none'}} href="#callbackForm" className="border-button">Contactez-nous</a>
                    </div>
                </div>
            </div>
        </div>

</React.Fragment>

    );
}
export default RequestCallBack;
