import React, { useEffect, useState } from "react";

import { ValeurService } from "../../../../services/ValeurService";
import DEV from '../../../../url';

export default function ToutSeTransforme() {
  const service = new ValeurService();

  const [rows, setRows] = useState([]);

  const fetchAll = () => {
    service.getAll().then((res) => {
      setRows(res);
    });
  };

  useEffect(() => {
    fetchAll();
  }, []);
  return (
    <div className="col-md-12">
      <div className="more-info-content">
        {rows.length === 0 ? (
          <></>
        ) : (
          <div className="row last">
            <div className="col-md-12 align-self-center">
              <div className="right-content" align="center">
                <span style={{ textTransform: "none" }}>
                  Dar Zaghouan - Rien ne se perd
                </span>
                <h2>
                  <em>Tout se transforme </em>
                </h2>
              </div>
            </div>

            {/*</Coverflow>*/}
          </div>
        )}

        <div className="row reserver-web-center">
          {rows.map((item, index) => (
            <div key={index} className="col-lg-4 col-md-6 col-sm-12 last pt-sm-1">
              <img
                style={{ height: 230, width: 220}}
                src={`${DEV}uploads/valeurs/${item?.img}`}
                alt={item?.title}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
