import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import {RestoService} from '../../../../../services/RestoService';
import {ContactInformation} from "../../../../../services/ContactInformation";
import HeadingRestauration from "../HeadingRestauration";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState} from "draft-js";
import {convertToHTML} from "draft-convert";
import DOMPurify from 'dompurify';
import IconPetitDejeuner from "../../../../../assets/images/iconpetitdej.png";
import DEV from '../../../../../url';
function PetitDejeunerDetails() {

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    const [convertedContent, setConvertedContent] = useState(null);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    }

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    }
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    const contact = new ContactInformation();
    const [rows, setRows] = useState([]);

    const fetchall = async () => {
        contact.all().then((res) => {
            setRows(res);
        });
    }
    useEffect(() => {
        fetchall();
    }, []);

    const {id} = useParams();
    const service = new RestoService();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState("");
    const [img, setImg] = useState("");
    const [rate, setRate] = useState(0);


    const [suggestions, setSuggestions] = useState([]);

    const getSuggestions = () => {
        service.all().then((res) => {
            setSuggestions(res);
        })
    }

    useEffect(() => {
        getSuggestions();
    }, []);


    const fetch = () => {
        service.get(id).then((res) => {
            setTitle(res.title);
            setDescription(res.description);
            setType(res.type);
            setImg(res.img);
            setRate(res.rate);

        });
    }


    useEffect(() => {
        fetch();
    }, []);

    

    const onRatingChange = () => {
        setRate(rate);
    };

    return (
        <React.Fragment>
            <HeadingRestauration/>
            <div className="container" id={"resto"}>

                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-12 hover03">
                            <figure className="figure mt-2">
                                {
                                    img ? <img height={"auto"}
                                               src={`${DEV}uploads/restaurants/${img}`}
                                               className="w-100 img-petit-dej" alt=""/> : <></>
                                }
                            </figure>
                        </div>

                        <div className="col-md-4 mt-5 ">
                            <div className="row">
                                <div className="col-md-4">
                                    <h4 className="name text-mobile-center">
                                        {title ? <React.Fragment>{title}</React.Fragment> :
                                            <React.Fragment></React.Fragment>} &nbsp;

                                    </h4>
                                    
                                </div>
                                <div className="col-lg-6 col-sm-12 text-mobile-center" align={"left"}>
                                    <StarRatings
                                        rating={rate}
                                        starRatedColor="green"
                                        numberOfStars={rate}
                                        name='rate'
                                        starDimension="20px"
                                        starSpacing={"1px"}

                                    />
                                </div>
                            </div>
                            <hr/>


                            <h5 className="price-container mt-2 text-mobile-center">
                                <b>Description du menu</b>
                            </h5>

                            <div className="row mt-3 ">
                                <div className="col-md-12 ">

                                    {
                                        description &&
                                        <p className='mt-4' dangerouslySetInnerHTML={createMarkup(description)}/>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>


                </div>


                <br/>
                <br/><br/>
                {
                    suggestions.length === 0 ?
                    <></> : 
                    <div className="row">
                    <div className="col-12 mt-5">
                        <h2 className={"text-center"}>Autres suggestions <hr
                            style={{border: '0.5px solid green', width: 200}}/>
                        </h2>
                        <div className="mt-5"></div>
                        {
                            suggestions
                                .map((item, index) => (
                                    <React.Fragment key={index}>
                                        {
                                            item.type === 'Petit déjeuner' ?
                                                <React.Fragment>
                                                    {
                                                        id == item?.id ? <div hidden={true}></div> :
                                                            <div key={index} className="col-sm-6 col-lg-4 mb-2-6 mb-lg-0 ">
                                                    <div className="card-wrapper mb-4">
                                                        <div className="card-img"><img width={350} height="300"
                                                                                       src={`${DEV}uploads/restaurants/${item?.img}`}
                                                                                       alt="..."/>
                                                        </div>
                                                        <div className="card-body">
                                                            <div>
                                                                <img width={140} src={IconPetitDejeuner}
                                                                     alt={item.title}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <div className="row">
                                                            <div className="col-lg-3">
                                                                <p style={{fontSize: 17}} className=" mb-2"><a href="#"
                                                                                                               className="text-secondary">
                                                                    <b>{item.title}</b></a></p>
                                                            </div>
                                                            <div className="col-lg-1"></div>
                                                            <div className="col-lg-8" align={"right"}>
                                                                {
                                                                    item.price == 0 ?
                                                                        <></> :
                                                                        <React.Fragment>
                                                                            <small className={"text-success"}><b>A partir de</b>
                                                                </small> <p className={"text-success"}>
                                                                <b>{item.price}{" DT / Personne"}</b></p>
                                                                        </React.Fragment>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="product-rating mb-2 mt-4">
                                                            <div className="row">
                                                                <div className="col-md-12 col-sm-12">
                                                                    <a href={`/breakfast-details${item.id}`}
                                                                       className="filled-btn btn-sm mt-2">
                                                                        Voir Détails
                                                                    </a>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    }
                                                </React.Fragment>
                                                : <></>
                                        }

                                    </React.Fragment>
                                ))
                        }
                    </div>
                </div>

                }
               
            </div>

            <div className="mt-5"></div>


        </React.Fragment>
    );
}

export default PetitDejeunerDetails;
