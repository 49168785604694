import React, { useState, useEffect } from "react";
import { FeedbackService } from "../../../../services/FeedbackService";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import UserAvatar from "react-user-avatar";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function TestimonialsItems() {
  const service = new FeedbackService();
  const [items, setItems] = useState([null]);

  useEffect(() => {
    service.all().then((res) => {
      setItems(res);
    });
  }, []);


  return (
    <div className="container">
      <div className="row d-flex justify-content-center align-items-center">
        <Carousel arrows={true} responsive={responsive} className="mt-2 w-100">
          {items.map((item, index) => (
            <div key={index} className="testimonial-item">
              {item?.approbation === "Oui" ? (
                <div className="inner-content">
                  <div align={"center"}>
                    <UserAvatar
                      colors={["#03AC13"]}
                      size="68"
                      name={item?.fullname}
                    />
                  </div>
                  <h4 className={"mt-3"}>{item?.fullname}</h4>
                  <span>{item?.subject}</span>

                  <p className="text-dark" style={{ color: "black" }}>
                    {" "}
                    "
                      <i className={"fa fa-edit text-success"}></i>{" "}
                      {item?.message}"
                  
                  </p>
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
export default TestimonialsItems;
