import axios from 'axios';
import DEV from "../url";

export class SpaService {

    all() {
        return axios.get(`${DEV}spa/all`).then((res) => res.data);
    }

     allSpa() {
        return axios.get(`${DEV}spa/allSpa`).then((res) => res.data);
    }

     allHammam() {
        return axios.get(`${DEV}spa/allHammam`).then((res) => res.data);
    }

    allHammamSpa() {
        return axios.get(`${DEV}spa/allHammamSpa`).then((res) => res.data);
    }

    async getSpaImg() {
        const res = await axios.get(`${DEV}spa/getSpaImages`);
        return res.data;
    }

    async getSpaVideos() {
        const res = await axios.get(`${DEV}spa/getSpaVideos`);
        return res.data;
    }

}
